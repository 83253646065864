/* eslint-disable @typescript-eslint/ban-ts-comment */
// Heavily inspired from https://github.com/codemirror/CodeMirror/blob/master/addon/tern/tern.js
import {
  ApplicationScope,
  containsBindingsAnywhere,
  getDynamicStringSegments,
} from "@superblocksteam/shared";
import CodeMirror, { Hint, Hints, Pos, cmpPos } from "codemirror";
import { CompletionsQuery, Server } from "tern";
import {
  AutocompleteConfiguration,
  HinterUpdateRequest,
} from "components/app/CodeEditor/EditorConfig";
import { DataTree } from "legacy/entities/DataTree/dataTreeFactory";
import { ApiScope } from "utils/dataTree/scope";
import {
  getDottedPathTo,
  isValidJSIdentifier,
  splitJSPath,
} from "utils/dottedPaths";
import logger from "utils/logger";
import {
  sortAndFilterExpandedCompletions,
  renderAutocompleteRow,
  compileKeyPathStr,
} from "./ExpandedAutocomplete";
import { customTreeTypeDefCreator } from "./customTreeTypeDefCreator";
import {
  appScopeDataTreeTypeDefCreator,
  DataTreeDef,
  dataTreeTypeDefCreator,
  Origin,
} from "./dataTreeTypeDefCreator";
import { functionInfo } from "./function-info";
import { DataType, getDataType } from "./getDataType";

import DEFS from "./staticDefs";
import { ExpandedAutocompleteDefinition, TypeInfoDocType } from "./types";
import {
  AUTOCOMPLETE_CLASS,
  elt,
  makeTooltip,
  WrappedTooltip,
  completionComparator,
  typeToIcon,
  makeDecrementedHintCompletion,
  renderHint,
} from "./util";
import TernWorkerServer from "./worker/TernWorkerService";

const bigDoc = 250;
const hintDelay = 1700;

type TernDocs = Record<string, TernDoc>;

type TernDoc = {
  doc: CodeMirror.Doc;
  name: string;
  changed: { to: number; from: number } | null;
};

type ArgHints = {
  start: CodeMirror.Position;
  type: { args: any[]; rettype: null | string };
  name: string;
  guess: boolean;
  doc: CodeMirror.Doc;
};

// Tern neither exports this type nor is there a clean definition inside that matches what we get.
// this is an approximation
type TernCallbackCompletion = {
  name: string;
  type?: string;
  depth?: number;
  doc?: TypeInfoDocType;
  url?: string;
  origin?: string;
  displayName?: string; // should this be displayText? This does not appear anywhere in Tern or in this file, but existing code uses it.
};

type TernCompletion = {
  data: TernCallbackCompletion;
  origin: string;
  type: DataType;
} & Hint;

let server: TernWorkerServer | null = null;

class TernServer {
  server: Server; // Required on instance
  docs: TernDocs = Object.create(null);
  cachedArgHints: ArgHints | null = null;
  bindingsDisabled: boolean | undefined;
  rendered: WrappedTooltip | undefined = undefined;
  private static refCount = 0;

  constructor(
    data: DataTree,
    additionalData: Record<string, Record<string, unknown>> | undefined,
    apiScope: ApiScope | undefined,
    appScope: ApplicationScope,
    configuration: AutocompleteConfiguration,
  ) {
    TernServer.refCount++;

    if (!server) {
      server = new TernWorkerServer(DEFS as any);
    }

    this.server = server;
    this.update({ data, additionalData, apiScope, appScope, configuration });
    this.bindingsDisabled = configuration.bindingsDisabled;
  }

  unregister() {
    TernServer.refCount--;
    if (TernServer.refCount === 0) {
      this.server.deleteDefs(Origin.CUSTOM_DATA_TREE);
      this.server.deleteDefs(Origin.DATA_TREE);
      this.server.deleteDefs(Origin.ENTITY_AC);

      server?.terminate?.();
      server = null;
    }
  }

  static getExpanded(
    data: undefined | { doc?: TypeInfoDocType },
  ): undefined | ExpandedAutocompleteDefinition {
    return data?.doc?.expanded;
  }

  static renderExpandedRow(el: HTMLElement, pos: Hints, cur: Hint) {
    const expanded = TernServer.getExpanded((cur as TernCompletion).data);
    if (expanded) {
      renderAutocompleteRow(expanded, cur.text, el);
    }
  }

  complete(cm: CodeMirror.Editor) {
    cm.showHint({
      hint: this.getHint.bind(this),
      completeSingle: false,
    });
  }

  showType(cm: CodeMirror.Editor) {
    this.showContextInfo(cm, "type");
  }

  showDocs(cm: CodeMirror.Editor) {
    this.showContextInfo(cm, "documentation", (data: any) => {
      if (data.url) {
        window.open(data.url, "_blank");
      }
    });
  }

  update(updateRequest: HinterUpdateRequest) {
    const dataTreeDef = dataTreeTypeDefCreator({
      dataTree: updateRequest.data,
      apiScope: updateRequest.apiScope,
      appScope: updateRequest.appScope,
      configuration: updateRequest.configuration,
    });

    let appDataTreeDef: DataTreeDef | null = null;
    if (updateRequest.appScope === ApplicationScope.PAGE) {
      appDataTreeDef = appScopeDataTreeTypeDefCreator({
        dataTree: updateRequest.data,
        apiScope: updateRequest.apiScope,
        appScope: ApplicationScope.APP,
        configuration: updateRequest.configuration,
      });
    }

    if (updateRequest.additionalData) {
      this.server.deleteDefs(Origin.CUSTOM_DATA_TREE);
      const customDataTreeDef = customTreeTypeDefCreator(
        updateRequest.additionalData,
      );
      this.server.addDefs(customDataTreeDef, true);
    }

    this.server.deleteDefs(Origin.DATA_TREE);
    this.server.deleteDefs(Origin.APP_DATA_TREE);

    if (appDataTreeDef) {
      this.server.addDefs(appDataTreeDef as any, true);
    }

    this.server.addDefs(dataTreeDef as any, true);
  }

  makeCompletion(
    name: string,
    config: {
      doc?: TypeInfoDocType;
      guess?: boolean;
      type?: string;
      origin?: string;
      displayName?: string; // similar to other comment - should this be displayText?
      doNotWrap?: boolean;
      decrementCursor?: number;
      completionInString?: boolean;
    },
    from: any,
  ): TernCompletion {
    const expanded = TernServer.getExpanded(config);
    const render = expanded ? TernServer.renderExpandedRow : undefined;

    const completionType = config.type ?? "?";
    let className = typeToIcon(completionType, config?.doc?.entityType);
    if (config.guess) className += " " + AUTOCOMPLETE_CLASS + "guess";

    let insertedText = name;
    const splitText = splitJSPath(name) ?? [];

    // If we are inserting an entire path, like `App.SomeVar`, then we should just insert that
    // otherwise lets wrap for safety
    if (
      config.doNotWrap ||
      /^fn\(/.test(completionType) ||
      splitText.length > 1
    ) {
      insertedText = name;
    } else {
      insertedText = getDottedPathTo(name);
    }

    // To handle when user types `API.response.` and we need to insert a key with spaces
    // like `API.response['user value']`
    const modifyCompletionInsertion =
      insertedText.startsWith("[") &&
      !isValidJSIdentifier(name) &&
      !config.completionInString;

    const textConfig = {
      text: modifyCompletionInsertion ? insertedText : name,
      from: modifyCompletionInsertion ? { ...from, ch: from.ch - 1 } : from,
      displayText: config.displayName || name,
    };

    const decrementCursor = config.decrementCursor;
    return {
      className: render ? undefined : className,
      render,
      data: { name, doc: config.doc, type: config.type, origin: config.origin },
      origin: config.origin ?? "",
      type: getDataType(completionType),
      ...textConfig,
      hint:
        typeof decrementCursor === "number"
          ? makeDecrementedHintCompletion(
              textConfig.text,
              decrementCursor,
              textConfig.from,
            )
          : undefined,
    };
  }

  requestCallback(
    error: any,
    data: { completions: TernCallbackCompletion[] } & Record<string, any>,
    cm: CodeMirror.Editor,
    resolve: any,
  ) {
    if (error) return this.showError(cm, error);
    if (data.completions.length === 0) {
      return this.showError(cm, "No suggestions");
    }
    const doc = this.findDoc(cm.getDoc());
    const cursor = cm.getCursor();
    const lineValue = this.lineValue(doc);
    const focusedValue = this.getFocusedDynamicValue(doc);
    const index = lineValue.indexOf(focusedValue);
    let completions: TernCompletion[] = [];
    const { start, end } = data;
    const from = {
      ...start,
      ch: start.ch + index,
      line: cursor.line,
    };
    const to = {
      ...end,
      ch: end.ch + index,
      line: cursor.line,
    };
    const seenNames = new Set<string>();

    for (let i = 0; i < data.completions.length; ++i) {
      const completion = data.completions[i];
      const expanded: undefined | ExpandedAutocompleteDefinition =
        TernServer.getExpanded(completion);
      if (expanded?.expandedShortcuts?.length) {
        for (const shortcut of expanded.expandedShortcuts) {
          const { keyPath, fnSignature, ...shortcutDef } = shortcut;
          const text = compileKeyPathStr(keyPath, fnSignature);
          const decrementCursor =
            functionInfo(shortcutDef).hasParams && text.endsWith(")") ? 1 : 0;
          completions.push(
            this.makeCompletion(
              text,
              {
                doc: shortcutDef["!doc"],
                type:
                  typeof shortcutDef["!type"] === "string"
                    ? shortcutDef["!type"]
                    : undefined,
                guess: data.guess,
                origin: completion.origin,
                doNotWrap: true,
                decrementCursor,
              },
              from,
            ),
          );
        }
      }

      // Completion begins with dot access or bracket access in a string, and we need
      // to know so that we can insert it properly
      const cursorPosition = cm.getCursor();
      const surroundingText = cm.getRange(
        { ...cursorPosition, ch: cursorPosition.ch - 1 },
        { ...cursorPosition, ch: cursorPosition.ch + 1 },
      );

      let name = completion.name;
      if (
        completion.origin === Origin.APP_DATA_TREE &&
        !name.startsWith("App.")
      ) {
        name = `App.${name}`;
      }

      if (!seenNames.has(name) && !completion.doc?.hidden) {
        completions.push(
          this.makeCompletion(
            name,
            {
              ...completion,
              guess: data.guess,
              decrementCursor:
                functionInfo(completion).hasParams &&
                completion.name.endsWith(")")
                  ? 1
                  : 0,
              completionInString:
                surroundingText === `''` || surroundingText === `""`,
            },
            from,
          ),
        );
      }

      seenNames.add(name);
    }
    completions = this.sortCompletions(completions);

    const obj = { from: from, to: to, list: completions };
    CodeMirror.on(obj, "close", () => this.removeWrappedTooltip());
    CodeMirror.on(obj, "update", () => this.removeWrappedTooltip());
    CodeMirror.on(obj, "select", (_cur: any, node: any) => {
      // the select event comes from the show-hint addon, thus
      // typescript does not know the right type for the parameters since only the base CodeMirror definitions are available
      const cur: TernCompletion = _cur;
      this.removeWrappedTooltip();
      this.rendered = renderHint(cm, {
        node,
        "!doc": cur.data.doc,
        "!type": cur.data.type,
      });
    });
    resolve(obj);

    return obj;
  }

  getHint(cm: CodeMirror.Editor) {
    return new Promise((resolve: (hints: CodeMirror.Hints) => void) => {
      this.request(
        cm,
        {
          type: "completions",
          types: true,
          docs: true,
          urls: true,
          origins: true,
          caseInsensitive: true,
          guess: false,
        },
        (error, data) => this.requestCallback(error, data, cm, resolve),
      );
    });
  }

  sortCompletions(completions: TernCompletion[]) {
    // Add data tree completions before others
    const expandedDefCompletions = new Array<TernCompletion>();
    const dataTreeCompletions = new Array<TernCompletion>();
    const appScopeCompletions = new Array<TernCompletion>();
    const docCompletions = new Array<TernCompletion>();
    const otherCompletions = new Array<TernCompletion>();
    for (const c of completions) {
      if (TernServer.getExpanded(c.data)) {
        expandedDefCompletions.push(c);
      } else if (
        c.origin === Origin.DATA_TREE ||
        c.origin === Origin.CUSTOM_DATA_TREE
      ) {
        dataTreeCompletions.push(c);
      } else if (c.origin === Origin.APP_DATA_TREE) {
        appScopeCompletions.push(c);
      } else if (c.origin === "[doc]") {
        docCompletions.push(c);
      } else {
        otherCompletions.push(c);
      }
    }

    dataTreeCompletions.sort((a, b) => completionComparator(a, b, "doc"));
    const processedExpandedCompletions = sortAndFilterExpandedCompletions(
      expandedDefCompletions,
      (a) => TernServer.getExpanded(a.data),
    );

    return docCompletions.concat(
      processedExpandedCompletions,
      dataTreeCompletions,
      appScopeCompletions,
      otherCompletions,
    );
  }

  showContextInfo(cm: CodeMirror.Editor, queryName: string, callbackFn?: any) {
    this.request(cm, { type: queryName, docs: true }, (error, data) => {
      if (error) return this.showError(cm, error);
      const tip = elt(
        "span",
        null,
        elt("strong", null, data.type || "not found"),
      );
      if (data.doc) tip.appendChild(document.createTextNode(" — " + data.doc));
      if (data.url) {
        tip.appendChild(document.createTextNode(" "));
        const child = tip.appendChild(elt("a", null, "[docs]"));
        // @ts-ignore: No types available
        child.href = data.url;

        // @ts-ignore: No types available
        child.target = "_blank";
      }
      this.tempTooltip(cm, tip);
      if (callbackFn) callbackFn(data);
    });
  }

  request(
    cm: CodeMirror.Editor,
    query: {
      type: string;
      types?: boolean;
      docs?: boolean;
      urls?: boolean;
      origins?: boolean;
      caseInsensitive?: boolean;
      preferFunction?: boolean;
      end?: CodeMirror.Position;
      guess?: boolean;
    },
    callbackFn: (error: any, data: any) => void,
    pos?: CodeMirror.Position,
  ) {
    const doc = this.findDoc(cm.getDoc());
    const request = this.buildRequest(doc, query, pos);
    this.server.request(request, callbackFn);
  }

  findDoc(doc: CodeMirror.Doc, name?: string): TernDoc {
    for (const n in this.docs) {
      const cur = this.docs[n];
      if (cur.doc === doc) return cur;
    }
    if (!name) {
      let n;
      for (let i = 0; ; ++i) {
        n = "[doc" + (i || "") + "]";
        if (!this.docs[n]) {
          name = n;
          break;
        }
      }
    }
    return this.addDoc(name, doc);
  }

  addDoc(name: string, doc: CodeMirror.Doc) {
    const data = { doc: doc, name: name, changed: null };
    this.server.addFile(name, this.getFocusedDynamicValue(data));
    CodeMirror.on(doc, "change", this.trackChange.bind(this));
    return (this.docs[name] = data);
  }

  buildRequest(
    doc: TernDoc,
    query: {
      type: string;
      types?: boolean;
      docs?: boolean;
      urls?: boolean;
      origins?: boolean;
      fullDocs?: any;
      lineCharPositions?: any;
      end?: any;
      start?: any;
      file?: any;
      docFormat?: string;
      includeKeywords?: boolean;
      depths?: boolean;
    },
    pos?: CodeMirror.Position,
  ): { query: CompletionsQuery; files: any[] } {
    const files = [];
    let offsetLines = 0;
    const allowFragments = !query.fullDocs;
    if (!allowFragments) delete query.fullDocs;
    query.lineCharPositions = true;
    if (!query.end) {
      const lineValue = this.lineValue(doc);
      const focusedValue = this.getFocusedDynamicValue(doc);
      const index = lineValue.indexOf(focusedValue);

      const positions = pos || doc.doc.getCursor("end");
      const queryChPosition = positions.ch - index;

      query.end = {
        ...positions,
        line: this.bindingsDisabled ? positions.line : 0,
        ch: queryChPosition,
      };

      if (doc.doc.somethingSelected()) {
        query.start = doc.doc.getCursor("start");
      }
    }
    const startPos = query.start || query.end;
    if (
      doc.changed &&
      doc.doc.lineCount() > bigDoc &&
      allowFragments &&
      doc.changed.to - doc.changed.from < 100 &&
      doc.changed.from <= startPos.line &&
      doc.changed.to > query.end.line
    ) {
      files.push(this.getFragmentAround(doc, startPos, query.end));
      query.file = "#0";
      offsetLines = files[0].offsetLines;
      if (query.start) {
        query.start = Pos(query.start.line - -offsetLines, query.start.ch);
      }
      query.end = Pos(query.end.line - offsetLines, query.end.ch);
    } else {
      files.push({
        type: "full",
        name: doc.name,
        text: this.bindingsDisabled
          ? doc.doc.getValue()
          : this.getFocusedDynamicValue(doc),
      });
      query.file = doc.name;
      doc.changed = null;
    }
    for (const name in this.docs) {
      const cur = this.docs[name];
      if (cur.changed && cur !== doc) {
        files.push({
          type: "full",
          name: cur.name,
          text: this.bindingsDisabled
            ? cur.doc.getValue()
            : this.getFocusedDynamicValue(cur),
        });
        cur.changed = null;
      }
    }
    query.docFormat = "full";
    query.includeKeywords = true;
    query.depths = true;

    return { query: query as CompletionsQuery, files: files };
  }

  trackChange(
    doc: CodeMirror.Doc,
    change: {
      to: CodeMirror.Position;
      from: CodeMirror.Position;
      text: string | any[];
    },
  ) {
    const data = this.findDoc(doc);

    const argHints = this.cachedArgHints;
    if (
      argHints &&
      argHints.doc === doc &&
      cmpPos(argHints.start, change.to) >= 0
    )
      this.cachedArgHints = null;

    let changed = data.changed;
    if (changed === null)
      data.changed = changed = { from: change.from.line, to: change.from.line };
    const end = change.from.line + (change.text.length - 1);
    if (change.from.line < changed.to)
      changed.to = changed.to - (change.to.line - end);
    if (end >= changed.to) changed.to = end + 1;
    if (changed.from > change.from.line) changed.from = change.from.line;

    if (doc.lineCount() > bigDoc && changed.to - changed.from > 100)
      setTimeout(() => {
        if (data.changed && data.changed.to - data.changed.from > 100)
          this.sendDoc(data);
      }, 200);
  }

  sendDoc(doc: TernDoc) {
    this.server.request(
      {
        // @ts-ignore: No types available
        files: [
          // @ts-ignore: No types available
          {
            type: "full",
            name: doc.name,
            text: this.getFocusedDynamicValue(doc),
          },
        ],
      },
      function (error: Error) {
        if (error) window.console.error(error);
        else doc.changed = null;
      },
    );
  }

  lineValue(doc: TernDoc) {
    const cursor = doc.doc.getCursor();

    return doc.doc.getLine(cursor.line);
  }

  docValue(doc: TernDoc) {
    return doc.doc.getValue();
  }

  getFocusedDynamicValue(doc: TernDoc) {
    const cursor = doc.doc.getCursor();
    const value = this.lineValue(doc);
    const stringSegments = getDynamicStringSegments(value);
    const dynamicStrings = stringSegments.filter((segment) => {
      if (containsBindingsAnywhere(segment)) {
        const index = value.indexOf(segment);

        if (cursor.ch >= index && cursor.ch <= index + segment.length) {
          return true;
        }
      }

      return false;
    });

    return dynamicStrings.length ? dynamicStrings[0] : value;
  }

  getFragmentAround(
    data: TernDoc,
    start: CodeMirror.Position,
    end: CodeMirror.Position,
  ) {
    const doc = data.doc;
    let minIndent = null;
    let minLine = null;
    let endLine;
    const tabSize = 4;
    for (let p = start.line - 1, min = Math.max(0, p - 50); p >= min; --p) {
      const line = doc.getLine(p),
        fn = line.search(/\bfunction\b/);
      if (fn < 0) continue;
      const indent = CodeMirror.countColumn(line, null, tabSize);
      if (minIndent != null && minIndent <= indent) continue;
      minIndent = indent;
      minLine = p;
    }
    if (minLine === null) minLine = Math.max(0, start.line - 1);
    const max = Math.min(doc.lastLine(), end.line + 20);
    if (
      minIndent === null ||
      minIndent ===
        CodeMirror.countColumn(doc.getLine(start.line), null, tabSize)
    )
      endLine = max;
    else
      for (endLine = end.line + 1; endLine < max; ++endLine) {
        const indent = CodeMirror.countColumn(
          doc.getLine(endLine),
          null,
          tabSize,
        );
        if (indent <= minIndent) break;
      }
    const from = Pos(minLine, 0);

    return {
      type: "part",
      name: data.name,
      offsetLines: from.line,
      text: doc.getRange(
        from,
        Pos(endLine, end.line === endLine ? undefined : 0),
      ),
    };
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  showError(cm: CodeMirror.Editor, msg: string) {
    // For debugging only. Otherwise No-op.
    // No sense in showing autocomplete errors to user.
    // this.tempTooltip(cm, String(msg));
    logger.debug(`Tern autocomplete failed: ${msg}`);
  }

  tempTooltip(cm: CodeMirror.Editor, content: HTMLElement | string) {
    if (cm.state.ternTooltip) {
      cm.state.ternTooltip.parentNode?.removeChild(cm.state.ternTooltip);
      delete cm.state.ternTooltip;
    }
    if (cm.state.completionActive) {
      // @ts-ignore: No types available
      cm.closeHint();
    }
    const where = cm.cursorCoords();
    const tip = (cm.state.ternTooltip = makeTooltip(
      // @ts-ignore: No types available
      where.right + 1,
      where.bottom,
      content,
    ));
    const maybeClear = () => {
      old = true;
      if (!mouseOnTip) clear();
    };
    const clear = () => {
      cm.state.ternTooltip = null;
      if (tip.parentNode) this.fadeOut(tip);
      clearActivity();
    };
    let mouseOnTip = false;
    let old = false;
    CodeMirror.on(tip, "mousemove", function () {
      mouseOnTip = true;
    });
    // @ts-ignore: CodeMirror probably supports setting event handlers on DOMElements
    CodeMirror.on(tip, "mouseout", function (e: MouseEvent) {
      const related = e.relatedTarget;
      // @ts-ignore: No types available
      if (!related || !CodeMirror.contains(tip, related)) {
        if (old) clear();
        else mouseOnTip = false;
      }
    });
    setTimeout(maybeClear, hintDelay);
    const clearActivity = this.onEditorActivity(cm, clear);
  }

  onEditorActivity(
    cm: CodeMirror.Editor,
    f: (instance: CodeMirror.Editor) => void,
  ) {
    cm.on("cursorActivity", f);
    cm.on("blur", f);
    cm.on("scroll", f);
    cm.on("swapDoc", f);
    return function () {
      cm.off("cursorActivity", f);
      cm.off("blur", f);
      cm.off("scroll", f);
      cm.off("swapDoc", f);
    };
  }

  remove(node?: HTMLElement) {
    if (node) {
      const p = node.parentNode;
      if (p) {
        p.removeChild(node);
        delete this.rendered;
      }
    }
  }

  removeWrappedTooltip() {
    if (this.rendered) {
      this.rendered.remove();
      delete this.rendered;
    }
  }

  fadeOut(tooltip: HTMLElement) {
    this.remove(tooltip);
  }
}

export default TernServer;
