import { Menu } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { colors } from "styles/colors";
import { getPluginById } from "utils/integrations";
import { AiAssistantOptionType, FlowType } from "./constants";
import { getIconForOption, getTitleForOption } from "./util";

const OptionIconImg = styled.img`
  height: 16px;
  width: 16px;
  margin-right: 8px;
`;

const IconWrapper = styled.span`
  height: 16px;
  margin-right: 8px;

  svg {
    height: 16px;
    width: 16px;
  }
`;

const StyledMenu = styled(Menu)`
  border-radius: 4px;
  border: none;
  min-width: 140px;
  padding: 8px;
  cursor: pointer;
  .ant-menu-item-group-title {
    cursor: default;
    padding: 0px;
    padding-left: 10px;
    font-size: 12px;
    color: ${(props) => props.theme.colors.GREY_300};
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 4px;
  }
  .ant-menu-item {
    padding: 5px 10px;
    height: 32px;
    line-height: 32px;
    margin: 0px;
    color: ${(props) => props.theme.colors.GREY_700};
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    &:hover {
      color: ${(props) => props.theme.colors.GREY_700};
    }

    &:not(:last-child) {
      margin-bottom: 0px;
    }

    &.ant-menu-item-selected {
      color: ${(props) => props.theme.colors.ACCENT_BLUE_500};
      background-color: ${colors.ACCENT_BLUE_500_50};
      &:hover {
        color: ${(props) => props.theme.colors.ACCENT_BLUE_500};
      }
      &.ant-menu-item-disabled {
        color: ${(props) => props.theme.colors.GREY_700};
        background-color: transparent;
      }
    }

    .ant-menu-title-content {
      display: flex;
      align-items: center;
    }
  }
`;

const OptionIconSvg = ({
  Icon,
}: {
  Icon: React.ComponentType<React.PropsWithChildren<unknown>>;
}) => {
  return (
    <IconWrapper>
      <Icon />
    </IconWrapper>
  );
};

const OptionList = ({
  options,
  onSelect,
  hideIcons,
  hasCode,
  pluginId,
}: {
  options: Array<AiAssistantOptionType>;
  onSelect: (option: AiAssistantOptionType) => void;
  hideIcons?: boolean;
  hasCode?: boolean;
  pluginId?: string;
}) => {
  const [activeOptionIndex, setActiveOptionIndex] = useState(0);
  const plugin = getPluginById(pluginId);
  const iconLocation = plugin?.iconLocation;

  const [dropdownOptions, enabledOptions] = useMemo(() => {
    const dropdownOptions = options.map((option) => {
      return {
        key: `${option.flowType}-${option.syntax}`,
        title: getTitleForOption(option),
        icon: getIconForOption(option, iconLocation),
        option,
        disabled:
          (option.flowType === FlowType.EXPLAIN ||
            option.flowType === FlowType.EDIT) &&
          !hasCode,
      };
    });
    const enabledOptions = dropdownOptions.filter(({ disabled }) => !disabled);
    return [dropdownOptions, enabledOptions];
  }, [options, hasCode, iconLocation]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowUp") {
        event.stopPropagation();
        event.preventDefault();
        const nextIndex =
          activeOptionIndex === 0
            ? enabledOptions.length - 1
            : activeOptionIndex - 1;
        setActiveOptionIndex(nextIndex);
      } else if (event.key === "ArrowDown") {
        event.stopPropagation();
        event.preventDefault();
        const nextIndex =
          activeOptionIndex === enabledOptions.length - 1
            ? 0
            : activeOptionIndex + 1;
        setActiveOptionIndex(nextIndex);
      } else if (event.key === "Enter") {
        event.stopPropagation();
        onSelect(enabledOptions[activeOptionIndex].option);
      }
    };
    document.addEventListener("keydown", handleKeyDown, true);
    return () => document.removeEventListener("keydown", handleKeyDown, true);
  }, [activeOptionIndex, enabledOptions, onSelect]);

  const onMouseEnter = useCallback(
    (index: number) => {
      const option = dropdownOptions[index];
      if (!option.disabled) {
        setActiveOptionIndex(
          enabledOptions.findIndex((o) => o.key === option.key),
        );
      }
    },
    [enabledOptions, dropdownOptions],
  );

  return (
    <StyledMenu selectedKeys={[enabledOptions[activeOptionIndex].key]}>
      <Menu.ItemGroup title="Ask AI">
        {dropdownOptions.map((option, i) => {
          return (
            <Menu.Item
              key={option.key}
              onClick={() => onSelect(option.option)}
              tabIndex={i}
              disabled={option.disabled}
              onMouseEnter={() => onMouseEnter(i)}
            >
              {!hideIcons &&
                (option.icon.type === "img" ? (
                  <OptionIconImg src={option.icon.src} />
                ) : (
                  <OptionIconSvg Icon={option.icon.component} />
                ))}
              <span>{option.title}</span>
            </Menu.Item>
          );
        })}
      </Menu.ItemGroup>
    </StyledMenu>
  );
};

export default OptionList;
