import {
  Dimension,
  WidgetProps,
  getNextEntityName,
} from "@superblocksteam/shared";
import { isString } from "lodash";
import { put, select } from "redux-saga/effects";
import { selectWidgets } from "legacy/actions/widgetActions";
import {
  PAGE_WIDGET_ID,
  WidgetType,
  WidgetTypes,
  CanvasLayout,
  CanvasDefaults,
  WIDGET_CAN_HAVE_CHILDREN,
  ATTACHED_WIDGET_AND_CAN_HAVE_CHILDREN,
  WidgetWidthModes,
} from "legacy/constants/WidgetConstants";
import { type WidgetConfigProps } from "legacy/mockResponses/WidgetConfigResponse";
import { getWidgetBlueprint } from "legacy/mockResponses/selectors";
import { flashElementById } from "legacy/pages/Editor/visibilityUtil";
import {
  CanvasWidgetsReduxState,
  FlattenedWidgetProps,
} from "legacy/reducers/entityReducers/canvasWidgetsReducer";
import { MetaState } from "legacy/reducers/entityReducers/metaReducer";
import { APP_MODE } from "legacy/reducers/types";
import {
  calculateNewWidgetPosition,
  calculateNewWidgetPositionOnMousePosition,
  updateSectionWidgetCanvasHeights,
  updateWidgetWidths,
} from "legacy/sagas/WidgetOperationsSagasUtils";
import {
  getAppMode,
  getResponsiveCanvasScaleFactor,
} from "legacy/selectors/applicationSelectors";
import {
  getFlattenedCanvasWidgets,
  getMainContainerWidgetId,
} from "legacy/selectors/editorSelectors";
import { getAllEntityNames, getWidgets } from "legacy/selectors/sagaSelectors";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import { getHstackCanvasRemainingWidthPx } from "legacy/utils/StackWidgetUtils";
import { dimensionToGridRows } from "legacy/utils/WidgetPropsUtils";
import { WidgetPropsRuntime } from "legacy/widgets/BaseWidget";
import { ColumnProperties } from "legacy/widgets/TableWidget/TableComponent/Constants";
import { isDynamicSize } from "legacy/widgets/base/sizing/dynamicLayoutUtils";
import {
  FlattenedWidgetLayoutProps,
  FlattenedWidgetLayoutMap,
} from "legacy/widgets/shared";
import { Flag, selectFlagById } from "store/slices/featureFlags";
import { fastClone } from "utils/clone";
import { sendMessage } from "utils/iframe";
import log from "utils/logger";
import AnalyticsUtil from "./AnalyticsUtil";
import { getCanvasSnapRows } from "./WidgetPropsUtils";
import { generateReactKey } from "./generators";
import type {
  CopiedWidgets,
  WidgetMap,
  WidgetTypeToPropType,
} from "legacy/widgets";

const findFirstParent = (
  initialWdgetId: string,
  widgets: CanvasWidgetsReduxState,
  matchFn: (widget: CanvasWidgetsReduxState[string]) => boolean,
) => {
  let parent = widgets[initialWdgetId];
  while (parent?.parentId) {
    if (matchFn(parent)) {
      return parent;
    }
    parent = widgets[parent.parentId];
  }
};

export function getPasteParentDetails({
  pasteTargetId,
  openModalOrSlideout,
  widgets,
  widgetMeta,
  copiedWidgets,
  copiedWidgetsType,
  lastPastedSingleWidgetId,
  forcePasteIntoContainer = false,
}: {
  pasteTargetId: string;
  openModalOrSlideout?: CanvasWidgetsReduxState[string];
  widgets: CanvasWidgetsReduxState;
  widgetMeta: MetaState;
  copiedWidgets: CopiedWidgets;
  copiedWidgetsType: WidgetType;
  lastPastedSingleWidgetId?: string;
  forcePasteIntoContainer?: boolean;
}): CanvasWidgetsReduxState[string] | undefined {
  const pasteTargetWidget = widgets[pasteTargetId];
  if (!pasteTargetWidget) return undefined;

  // Differences are for Page, Section, or anything else.
  // For Page, we only want to paste into the page or the open modal or slideout if it's a Section that's copied.
  // For copied modals and slideous, the page is always the target
  // For columns, we find the closest section
  // For everything else we find the closest canvas
  switch (copiedWidgetsType) {
    case WidgetTypes.SLIDEOUT_WIDGET:
    case WidgetTypes.MODAL_WIDGET: {
      return widgets[PAGE_WIDGET_ID];
    }
    case WidgetTypes.SECTION_WIDGET: {
      return openModalOrSlideout || widgets[PAGE_WIDGET_ID];
    }
    case WidgetTypes.CANVAS_WIDGET: {
      // this must be a column, as it's the only canvas widget that can be copied directly

      // Find the closest parent that is a section
      return findFirstParent(
        pasteTargetId,
        widgets,
        (widget) => widget.type === WidgetTypes.SECTION_WIDGET,
      );
    }
    default: {
      switch (pasteTargetWidget.type) {
        case WidgetTypes.PAGE_WIDGET: {
          const page = widgets[PAGE_WIDGET_ID];
          const firstSectionId = page?.children?.[0];
          const firstColumnId = widgets[firstSectionId || ""]?.children?.[0];
          const firstColumnWidget = widgets[firstColumnId || ""];
          return firstColumnWidget;
        }
        case WidgetTypes.MODAL_WIDGET:
        case WidgetTypes.SLIDEOUT_WIDGET: {
          // find the first section first canvas of the modal or slideout
          const firstSectionId = pasteTargetWidget.children?.[0];
          const firstCanvasId = widgets[firstSectionId || ""]?.children?.[0];
          return widgets[firstCanvasId || ""];
        }
        case WidgetTypes.CANVAS_WIDGET:
          return pasteTargetWidget;
        case WidgetTypes.CONTAINER_WIDGET:
        case WidgetTypes.FORM_WIDGET:
        case WidgetTypes.SECTION_WIDGET:
        case WidgetTypes.GRID_WIDGET: {
          const singleCopiedContainerWidget =
            copiedWidgets.length === 1 &&
            copiedWidgets[0].list[0].type === WidgetTypes.CONTAINER_WIDGET
              ? copiedWidgets[0].list[0]
              : undefined;

          const lastPastedSingleWidget = lastPastedSingleWidgetId
            ? widgets[lastPastedSingleWidgetId]
            : undefined;

          const lastPastedWidgetIsContainer = lastPastedSingleWidget
            ? ATTACHED_WIDGET_AND_CAN_HAVE_CHILDREN.includes(
                lastPastedSingleWidget.type as WidgetTypes,
              )
            : false;

          /**
           * Two rules for pasting into a container target:
           * 1. we don't paste a single copied container into the same container it was copied from, instead we paste as a sibling of the copied container as that's usually what people want.
           * 2. If the last pasted widget was a single container, and we're pasting a single container now, we paste as a sibling of the last pasted container rather than inside it. This allows a user to cmd+c a container, then cmd+v 5 times in a row and ensure they're ALL pasted as siblings, not just the first paste
           */

          const singleCopiedWidgetIsSameAsPasteTarget =
            singleCopiedContainerWidget?.widgetId ===
            pasteTargetWidget.widgetId;
          if (
            singleCopiedContainerWidget &&
            pasteTargetWidget.children &&
            pasteTargetWidget.type !== WidgetTypes.GRID_WIDGET &&
            !forcePasteIntoContainer &&
            (singleCopiedWidgetIsSameAsPasteTarget ||
              (lastPastedWidgetIsContainer &&
                lastPastedSingleWidgetId ===
                  singleCopiedContainerWidget.widgetId))
          ) {
            return widgets[pasteTargetWidget.parentId];
          }

          return widgets[pasteTargetWidget.children?.[0] || ""];
        }
        case WidgetTypes.TABS_WIDGET: {
          const selectedTabWidgetId = widgetMeta?.[pasteTargetWidget.widgetId]
            ?.selectedTabWidgetId as string | undefined;
          return widgets[selectedTabWidgetId || ""];
        }
        default:
          // just find the first parent canvas
          return findFirstParent(
            pasteTargetId,
            widgets,
            (widget) => widget.type === WidgetTypes.CANVAS_WIDGET,
          );
      }
    }
  }
}

export const updatePastedTabsWidget = (
  widget: Omit<WidgetProps, "children">,
  widgetIdMap: Record<string, string>,
) => {
  if (widget.type === WidgetTypes.TABS_WIDGET) {
    try {
      const tabWidget =
        widget as unknown as WidgetTypeToPropType[WidgetTypes.TABS_WIDGET];
      const tabs = tabWidget.tabs;
      if (tabs && Array.isArray(tabs)) {
        tabWidget.tabs = tabs.map((tab) => {
          tab.widgetId = widgetIdMap[tab.widgetId];
          return tab;
        });
      }
    } catch (error) {
      log.debug("Error updating tabs");
      log.debug(error);
    }
  }
};

export const updatePastedTableWidget = (
  widget: Omit<WidgetProps, "children">,
  isRename: boolean,
  newWidgetName: string,
) => {
  if (widget.type === WidgetTypes.TABLE_WIDGET && isRename) {
    const tableWidget =
      widget as unknown as WidgetTypeToPropType[WidgetTypes.TABLE_WIDGET];
    try {
      const oldWidgetName = widget.widgetName;

      // If the primaryColumns of the table exist
      if (tableWidget.primaryColumns) {
        // For each column
        for (const [columnId, column] of Object.entries(
          tableWidget.primaryColumns,
        )) {
          // For each property in the column
          for (const [rawKey, value] of Object.entries(column)) {
            // Replace reference of previous widget with the new widgetName
            // This handles binding scenarios like `{{Table2.tableData.map((currentRow) => (currentRow.id))}}`
            const key = rawKey as keyof ColumnProperties;
            tableWidget.primaryColumns[columnId][key] = (
              isString(value)
                ? value.replace(`${oldWidgetName}.`, `${newWidgetName}.`)
                : value
            ) as never;
          }
        }
      }
      // also update any CACHED settings, in case they are used
      if (tableWidget.cachedColumnSettings) {
        for (const [columnId, column] of Object.entries(
          tableWidget.cachedColumnSettings,
        )) {
          for (const [rawKey, value] of Object.entries(column)) {
            const key = rawKey as keyof ColumnProperties;
            tableWidget.cachedColumnSettings[columnId][key] = (
              isString(value)
                ? value.replace(`${oldWidgetName}.`, `${newWidgetName}.`)
                : value
            ) as never;
          }
        }
      }
    } catch (error) {
      log.debug("Error updating table component properties");
      log.debug(error);
    }
  }
};

export function* flashPastedWidget(newWidgetsIds: string[]) {
  const newWidgetId = newWidgetsIds[newWidgetsIds.length - 1];
  const isIframeEnabled: boolean = yield select(
    selectFlagById,
    Flag.ENABLE_IFRAME,
  );
  if (!isIframeEnabled) {
    // Flash the newly pasted widget once the DSL is re-rendered
    // if timeout is smaller than 200 and copying > 1 widgets including container flash will not work
    setTimeout(() => flashElementById(newWidgetId), 200);
  } else {
    sendMessage({
      type: "flash-element",
      payload: { elementId: newWidgetId },
    });
  }

  yield put(selectWidgets(newWidgetsIds));
}

/*
  This function is used to generate a the state of the canvas widgets after a widget or widgets have been pasted.
*/
export function* pasteWidgets({
  copiedWidgets,
  parentWidget,
  pasteAtCursor,
  mousePosition,
  insertionIndex,
  recalculateWidgetPositions,
}: {
  copiedWidgets: CopiedWidgets;
  parentWidget: CanvasWidgetsReduxState[string];
  pasteAtCursor?: boolean;
  mousePosition?: { x: number; y: number };
  insertionIndex?: number;
  recalculateWidgetPositions: boolean;
}) {
  const stateWidgets: ReturnType<typeof getWidgets> = yield select(getWidgets);
  // some props like parentRowSpace or parentColSpace is calculated in render time
  let widgets: WidgetMap = { ...stateWidgets };
  const widgetIdMap: Record<string, string> = {};

  const appMode: APP_MODE = yield select(getAppMode) ?? APP_MODE.PUBLISHED;
  const theme: ReturnType<typeof selectGeneratedTheme> = yield select(
    selectGeneratedTheme,
  );
  const canvasScaleFactor: ReturnType<typeof getResponsiveCanvasScaleFactor> =
    yield select(getResponsiveCanvasScaleFactor);
  const widgetsRuntime: FlattenedWidgetLayoutMap = yield select(
    getFlattenedCanvasWidgets,
  );

  const flattenedWidgets: FlattenedWidgetLayoutMap = yield select(
    getFlattenedCanvasWidgets,
  );

  const parentWidgetRunTime = widgetsRuntime[parentWidget.widgetId];
  const parentCanvasRunTime =
    parentWidget.type === WidgetTypes.CANVAS_WIDGET
      ? // if you select one widget in container, parentWidget is container's inner canvas
        parentWidgetRunTime
      : // if you select nothing in the container, parentWidget is container, first children is canvas
      parentWidgetRunTime.children?.[0]
      ? widgetsRuntime[parentWidgetRunTime.children[0]]
      : parentWidgetRunTime;

  // We only try to cop
  const copyToMousePositionOnGrid =
    pasteAtCursor &&
    copiedWidgets.length === 1 &&
    mousePosition &&
    parentCanvasRunTime.type === WidgetTypes.CANVAS_WIDGET;

  let numInserted = 0; // only for pasting in stack

  // For tracking the new ids of the widgets that are pasted
  // at the top level
  const newRootWidgetIds: string[] = [];

  // For tracking new widget names, we need to keep track of names used across all copied widgets
  const entityNames: string[] = yield select(getAllEntityNames);
  const mutableEntityNames = [...entityNames];

  // loop to copy the copiedWidgets & descendants
  for (let i = 0; i < copiedWidgets.length; i++) {
    const copiedWidgetId = copiedWidgets[i].widgetId;
    // list is all descendants of current copied widget
    const copiedWidget = copiedWidgets[i].list.find(
      (widget) => widget.widgetId === copiedWidgetId,
    );
    if (copiedWidget) {
      // Log the paste event
      AnalyticsUtil.logEvent("WIDGET_PASTE", {
        widgetName: copiedWidget.widgetName,
        widgetType: copiedWidget.type,
      });
      let position: ReturnType<typeof calculateNewWidgetPosition> | undefined;
      if (recalculateWidgetPositions) {
        // Compute the new widget's positional properties
        position = copyToMousePositionOnGrid
          ? yield calculateNewWidgetPositionOnMousePosition({
              widget: copiedWidget as WidgetPropsRuntime &
                Partial<WidgetConfigProps>,
              // parentId: newWidgetParentId,
              parentId: parentWidget.widgetId,
              canvasWidgets:
                widgetsRuntime as unknown as CanvasWidgetsReduxState, // todo (layouts): fix types
              canvasScaleFactor,
              mousePosition: mousePosition,
              colWidth: parentCanvasRunTime.parentColumnSpace ?? 0,
              rowHeight: parentCanvasRunTime.parentRowSpace ?? 0,
              parentLeft: parentCanvasRunTime.left.value,
              parentTop: parentCanvasRunTime.top.value,
              parentRows: getCanvasSnapRows(
                parentCanvasRunTime.height,
                parentCanvasRunTime.minHeight,
              ),
              parentCols: parentCanvasRunTime.gridColumns as number,
              paddingLeft:
                Dimension.toPx(
                  parentCanvasRunTime?.padding?.left,
                  parentCanvasRunTime.parentColumnSpace ?? 0,
                )?.value ?? 0,
              paddingTop:
                Dimension.toPx(
                  parentCanvasRunTime?.padding?.top,
                  parentCanvasRunTime.parentRowSpace ?? 0,
                )?.value ?? 0,
            })
          : calculateNewWidgetPosition({
              widget: copiedWidget,
              parentId: parentWidget.widgetId,
              canvasWidgets: widgets,
            });
      }

      // Get a flat list of all the widgets to be updated
      const widgetList = copiedWidgets[i].list;
      const newWidgetList: FlattenedWidgetProps[] = [];
      // Generate new widgetIds for the flat list of all the widgets to be updated
      widgetList.forEach((widget, widgetListIndex) => {
        // Create a copy of the widget properties
        const newWidget = fastClone(widget);
        newWidget.widgetId = generateReactKey();
        // Add the new widget id so that it maps the previous widget id
        widgetIdMap[widget.widgetId] = newWidget.widgetId;
        // Add the new widget to the list
        newWidgetList.push(newWidget);

        if (widgetListIndex === 0) {
          newRootWidgetIds.push(newWidget.widgetId);
        }
      });

      // For each of the new widgets generated
      for (let j = 0; j < newWidgetList.length; j++) {
        const widget = newWidgetList[j];

        // If a widget by the same name exists in the canvas, or is part of the new widget names we've generated, give it a new name
        const isRenameWidget = mutableEntityNames.includes(widget.widgetName);
        const widgetConfig: ReturnType<typeof getWidgetBlueprint> =
          yield select(getWidgetBlueprint, widget.type);
        const newWidgetName = getNextEntityName(
          widgetConfig.widgetName,
          mutableEntityNames,
        );
        mutableEntityNames.push(
          isRenameWidget ? newWidgetName : widget.widgetName,
        );

        // Update the children widgetIds if it has children
        if (widget.children && widget.children.length > 0) {
          widget.children.forEach((childWidgetId: string, index: number) => {
            if (widget.children) {
              widget.children[index] = widgetIdMap[childWidgetId];
            }
          });
        }

        // Update the tabs for the tabs widget.
        updatePastedTabsWidget(widget, widgetIdMap);
        // Update the table widget column properties
        updatePastedTableWidget(widget, isRenameWidget, newWidgetName);

        // If it is the copied widget, update position properties
        if (widget.widgetId === widgetIdMap[copiedWidget.widgetId]) {
          if (recalculateWidgetPositions && position) {
            widget.left = position.left;
            widget.top = position.top;
            widget.width = position.width;
            widget.height = position.height;
          }

          // widget.parentId = newWidgetParentId;
          widget.parentId = parentWidget.widgetId;

          // Also, update the parent widget in the canvas widgets
          // to include this new copied widget's id in the parent's children

          // by default, solo child
          let parentChildren = [widget.widgetId];
          if (
            widgets[parentWidget.widgetId]?.children &&
            Array.isArray(widgets[parentWidget.widgetId].children)
          ) {
            if (insertionIndex == null) {
              // Add the new child to the end of existing children
              parentChildren = (
                widgets[parentWidget.widgetId].children ?? []
              ).concat([widget.widgetId]);
            } else {
              // then we are inserting into a vstack
              parentChildren = [
                ...(widgets[parentWidget.widgetId].children ?? []),
              ];
              parentChildren.splice(
                insertionIndex + numInserted,
                0,
                widget.widgetId,
              );
              numInserted++;
            }
          }
          widgets = {
            ...widgets,
            [parentWidget.widgetId]: {
              ...widgets[parentWidget.widgetId],
              children: parentChildren,
            },
          };
          if (recalculateWidgetPositions) {
            // If the copied widget's boundaries exceed the parent's
            // Make the parent scrollable
            const parent = widgets[parentWidget.widgetId];
            if (
              Dimension.add(parent.top, dimensionToGridRows(parent.height))
                .value <=
                Dimension.add(widget.top, dimensionToGridRows(widget.height))
                  .value &&
              widget.parentId !== PAGE_WIDGET_ID
            ) {
              parent.height = Dimension.gridUnit(
                Dimension.add(widget.top, dimensionToGridRows(widget.height))
                  .value,
              );
              parent.canExtend = true;
              widgets[parent.widgetId] = parent;

              const parentsParent = fastClone(widgets[parent.parentId]);
              parentsParent.shouldScrollContents = true;
              widgets[parentsParent.widgetId] = parentsParent;
            }
          }
        } else {
          // For all other widgets in the list
          // (These widgets will be descendants of the copied widget)
          // This means, that their parents will also be newly copied widgets
          // Update widget's parent widget ids with the new parent widget ids

          const newParentId = newWidgetList.find(
            (newWidget) => newWidget.widgetId === widgetIdMap[widget.parentId],
          )?.widgetId;
          if (newParentId) widget.parentId = newParentId;
        }

        if (isRenameWidget) {
          widget.widgetName = newWidgetName;
        }

        // Add the new widget to the canvas widgets
        widgets[widget.widgetId] = widget;
      }

      if (recalculateWidgetPositions && position) {
        const grandParent = widgets[parentWidget.parentId];
        if (
          grandParent &&
          parentWidget.type === WidgetTypes.CANVAS_WIDGET &&
          grandParent.type === WidgetTypes.SECTION_WIDGET
        ) {
          updateSectionWidgetCanvasHeights(
            widgets,
            theme,
            appMode,
            grandParent,
          );
        }

        // if parent is main canvas, we need to extend the bottom
        const topCanvasId: string = yield select(getMainContainerWidgetId);
        if (
          parentWidget.widgetId === topCanvasId &&
          widgets[topCanvasId] &&
          position.height.value + 1 > widgets[topCanvasId].height.value
        ) {
          widgets[topCanvasId].height = Dimension.add(
            dimensionToGridRows(position.height),
            Dimension.gridUnit(2),
          ).asFirst();
        }
      }
    }
  }

  // If the parent is an H-stack, we need to resize down the children to fit
  shrinkWidgetsInHstack({
    parentWidget,
    parentWidgetRunTime: parentCanvasRunTime,
    widgets,
    flattenedWidgets,
    childrenIds: newRootWidgetIds,
  });

  const newWidgetsIds = copiedWidgets.map(
    (copiedWidget) => widgetIdMap[copiedWidget.widgetId],
  );

  return {
    widgets,
    newWidgetsIds,
    parentCanvasRunTime,
  };
}

export function shrinkWidgetsInHstack({
  parentWidget,
  parentWidgetRunTime,
  widgets,
  flattenedWidgets,
  childrenIds,
  useFullWidth,
}: {
  parentWidget: CanvasWidgetsReduxState[string];
  parentWidgetRunTime: FlattenedWidgetLayoutProps;
  widgets: CanvasWidgetsReduxState;
  flattenedWidgets: FlattenedWidgetLayoutMap;
  childrenIds: string[];
  useFullWidth?: boolean;
}) {
  if (
    parentWidget.type === WidgetTypes.CANVAS_WIDGET &&
    parentWidget.layout === CanvasLayout.HSTACK
  ) {
    const parentColumnSpace = parentWidgetRunTime.parentColumnSpace || 1;

    // parentWidget.width is gridUnit but 0 due to some old canvases only relying on gridColumns
    // this discrepancy is resolved by recalculateWidgetsLayout in size.ts though, so we can
    // just use the flattenedWidgets width here
    const parentRemainingWidth = useFullWidth
      ? Dimension.toPx(
          flattenedWidgets[parentWidget.widgetId].width,
          parentColumnSpace,
        ).value
      : getHstackCanvasRemainingWidthPx(
          parentWidgetRunTime as unknown as FlattenedWidgetProps, // todo (layouts): fix types
          widgets,
        );

    let newWidgetMaxWidthPx = parentRemainingWidth / childrenIds.length;

    if (parentRemainingWidth <= 0) {
      newWidgetMaxWidthPx =
        CanvasDefaults.MIN_GRID_UNIT_WIDTH * parentColumnSpace;
    }
    childrenIds.forEach((widgetId) => {
      // Update the width
      if (
        WIDGET_CAN_HAVE_CHILDREN.includes(widgets[widgetId].type as WidgetTypes)
      ) {
        const widgetWidthPx = Dimension.toPx(
          widgets[widgetId].width,
          parentColumnSpace,
        );

        const widgetNewWidthPx = Math.min(
          widgetWidthPx.value,
          newWidgetMaxWidthPx,
        );

        const widthDiffGU = Dimension.toGridUnit(
          Dimension.px(widgetNewWidthPx - widgetWidthPx.value),
          parentColumnSpace,
        ).raw();
        if (widthDiffGU.value < 0) {
          updateWidgetWidths({
            widgets,
            flattenedWidgets,
            widget: widgets[widgetId],
            widthDiffGU: widthDiffGU.value,
          });
        }
      }
      // Don't update width if is dynamic
      else if (!isDynamicSize(widgets[widgetId].width.mode)) {
        let newWidth: Dimension<WidgetWidthModes>;
        const widgetWidthPx = Dimension.toPx(
          widgets[widgetId].width,
          parentColumnSpace,
        );

        const newWidgetWidthPx = Math.min(
          newWidgetMaxWidthPx,
          widgetWidthPx.value,
        );
        const currentWidthPx = Dimension.toPx(
          widgets[widgetId].width,
          parentColumnSpace,
        ).value;
        if (newWidgetWidthPx < currentWidthPx) {
          if (widgets[widgetId].width.mode === "px") {
            newWidth = Dimension.px(newWidgetWidthPx);
          } else {
            newWidth = Dimension.toGridUnit(
              Dimension.px(newWidgetWidthPx),
              parentColumnSpace,
            ).roundUp();
          }

          widgets[widgetId] = {
            ...widgets[widgetId],
            width: newWidth,
          };
        }
      }
    });
  }
}
