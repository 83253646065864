import { DatasourceMetadataDto } from "@superblocksteam/shared";
import React, { useCallback, useMemo } from "react";

import { SyntaxType } from "code-formatting/constants";
import { useFeatureFlag } from "hooks/ui";
import { Flag } from "store/slices/featureFlags";
import AiBaseFlow from "./AiAssistantBaseFlow";

import { AiAssistantOptionType, FlowType } from "./constants";
import {
  generateRequestBodyForTask,
  getEditorModeForSyntax,
  getTitleForOption,
} from "./util";

const PRESET_OPTIONS = [
  {
    label: "Optimize performance",
    value: "Optimize performance",
  },
  {
    label: "Add comments",
    value: "Add comments",
  },
  {
    label: "Format code",
    value: "Format code",
  },
];

const AiEditFlow = (props: {
  onConfirm: (
    aiAssistantOptionType: AiAssistantOptionType,
    code?: string,
  ) => void;
  onClose: () => void;
  option: AiAssistantOptionType;
  allCode: string;
  integrationId?: string;
  codeToEdit: string;
  firstLineNumber: number;
  configurationId?: string;
  datasourceMeta?: DatasourceMetadataDto;
}) => {
  const {
    onConfirm,
    onClose,
    option,
    integrationId,
    configurationId,
    allCode,
    codeToEdit,
    firstLineNumber,
    datasourceMeta,
  } = props;

  const editorMode = getEditorModeForSyntax(option.syntax);
  const aiMetadataEnabled = useFeatureFlag(Flag.ENABLE_AI_ASSISTANT_METADATA);

  const getRequestBody = useCallback(
    (userInput: string) => {
      return generateRequestBodyForTask({
        userInput,
        syntax: option.syntax,
        flowType: FlowType.EDIT,
        integrationId,
        configurationId,
        allCode,
        selectedCode: codeToEdit,
        datasourceMeta,
        metadataEnabled: aiMetadataEnabled,
      });
    },
    [
      option.syntax,
      datasourceMeta,
      allCode,
      codeToEdit,
      integrationId,
      configurationId,
      aiMetadataEnabled,
    ],
  );

  const title = useMemo(() => `${getTitleForOption(option)}`, [option]);
  const placeholder = useMemo(() => {
    switch (option.syntax) {
      case SyntaxType.PYTHON:
      case SyntaxType.JAVASCRIPT:
      case SyntaxType.BINDING:
        return "i.e. add error handling";
      default:
        return "i.e. sort by date";
    }
  }, [option]);

  const diffViewerProps = useMemo(
    () => ({
      originalCode: codeToEdit || allCode,
      firstLineNumber,
    }),
    [codeToEdit, allCode, firstLineNumber],
  );

  return (
    <AiBaseFlow
      onConfirm={onConfirm}
      onClose={onClose}
      option={option}
      getRequestBody={getRequestBody}
      editorMode={editorMode}
      title={title}
      placeholder={placeholder}
      diffViewerProps={diffViewerProps}
      responseViewType="diff"
      presetOptions={PRESET_OPTIONS}
    />
  );
};

export default AiEditFlow;
