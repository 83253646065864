import { EditorLanguage, FormComponentType, InputDataType, Plugin, PluginResponseType, PluginType, FormItem } from '../../types';
import { PARAMETERIZED_SQL_DESCRIPTION, SQL_INITIAL_TEXT } from './constants';
import { makeDropdownItem } from './shared/db';

export const SnowflakePluginVersions = {
  V1: '0.0.1',
  V2: '0.0.2',
  V6: '0.0.6',
  V7: '0.0.7'
};

const CONNECTION_METHODS_AND_DISPLAY_NAMES = {
  okta: 'Native SSO',
  fields: 'Password-based Authentication'
};

const BASE_DATABASE_ACCOUNT = {
  label: 'Database account',
  name: 'authentication.custom.account.value',
  componentType: FormComponentType.INPUT_TEXT,
  placeholder: 'xyz12345.us-east-1',
  rules: [{ required: true, message: 'Database account is required' }],
  tooltip: {
    markdownText:
      'Use the [Account Identifier](https://docs.snowflake.com/en/user-guide/admin-account-identifier#non-vps-account-locator-formats-by-cloud-platform-and-region) based on your cloud platform and region.'
  }
};

const BASE_USERNAME = {
  label: 'Database username',
  name: 'authentication.username',
  componentType: FormComponentType.INPUT_TEXT,
  rules: [{ required: true, message: 'Database username is required' }]
};

const BASE_PASSWORD = {
  label: 'Database password',
  name: 'authentication.password',
  componentType: FormComponentType.INPUT_TEXT,
  dataType: InputDataType.PASSWORD,
  rules: [{ required: true, message: 'Database password is required' }]
};

const BASE_DATABASE_NAME = {
  label: 'Database name',
  name: 'authentication.custom.databaseName.value',
  componentType: FormComponentType.INPUT_TEXT,
  rules: [{ required: true, message: 'Database name is required' }]
};

const BASE_DATABASE_WAREHOUSE = {
  label: 'Database warehouse (optional)',
  name: 'authentication.custom.warehouse.value',
  startVersion: SnowflakePluginVersions.V1,
  componentType: FormComponentType.INPUT_TEXT
};

const BASE_DATABASE_SCHEMA = {
  label: 'Database schema (optional)',
  name: 'authentication.custom.schema.value',
  startVersion: SnowflakePluginVersions.V1,
  componentType: FormComponentType.INPUT_TEXT,
  placeholder: 'PUBLIC'
};

const BASE_ROLE = {
  label: 'Role (optional)',
  name: 'authentication.custom.role.value',
  startVersion: SnowflakePluginVersions.V1,
  componentType: FormComponentType.INPUT_TEXT,
  tooltip: { markdownText: 'The default security role to use for the session after connecting.' }
};

export const SnowflakePlugin: Plugin = {
  id: 'snowflake',
  name: 'Snowflake',
  moduleName: 'SnowflakePlugin',
  modulePath: 'plugins/snowflake/SnowflakePlugin',
  iconLocation: 'https://superblocks.s3-us-west-2.amazonaws.com/img/integrations/snowflake.png',
  docsUrl: 'https://docs.superblocks.com/integrations/integrations-library/snowflake',
  type: PluginType.DB,
  responseType: PluginResponseType.TABLE,
  hasRawRequest: true,
  hasMetadata: true,
  rawRequestName: 'Executed SQL',
  datasourceTemplate: {
    sections: [
      {
        name: 'main',
        items: [
          {
            label: 'Display name',
            name: 'name',
            startVersion: SnowflakePluginVersions.V1,
            componentType: FormComponentType.INPUT_TEXT,
            placeholder: 'Snowflake Prod',
            rules: [{ required: true, message: 'Display name is required' }]
          },
          {
            label: 'Connection method',
            name: 'connectionType',
            startVersion: SnowflakePluginVersions.V7,
            componentType: FormComponentType.DROPDOWN,
            initialValue: 'fields',
            rules: [{ required: true }],
            options: Object.entries(CONNECTION_METHODS_AND_DISPLAY_NAMES).map(([value, displayName]) =>
              makeDropdownItem(value, displayName)
            )
          },
          // DATABASE ACCOUNT
          { ...BASE_DATABASE_ACCOUNT, startVersion: SnowflakePluginVersions.V1, endVersion: SnowflakePluginVersions.V6 } as FormItem,
          {
            ...BASE_DATABASE_ACCOUNT,
            startVersion: SnowflakePluginVersions.V7,
            display: {
              show: {
                connectionType: ['fields', 'okta']
              }
            }
          } as FormItem,
          // USERNAME
          { ...BASE_USERNAME, startVersion: SnowflakePluginVersions.V1, endVersion: SnowflakePluginVersions.V6 } as FormItem,
          {
            ...BASE_USERNAME,
            startVersion: SnowflakePluginVersions.V7,
            display: {
              show: {
                connectionType: ['fields']
              }
            }
          } as FormItem,
          {
            label: 'Okta username',
            name: 'authentication.username',
            componentType: FormComponentType.INPUT_TEXT,
            rules: [{ required: true, message: 'Okta username is required' }],

            startVersion: SnowflakePluginVersions.V7,
            display: {
              show: {
                connectionType: ['okta']
              }
            }
          },
          // PASSWORD
          { ...BASE_PASSWORD, startVersion: SnowflakePluginVersions.V1, endVersion: SnowflakePluginVersions.V6 } as FormItem,
          {
            ...BASE_PASSWORD,
            startVersion: SnowflakePluginVersions.V7,
            display: {
              show: {
                connectionType: ['fields']
              }
            }
          } as FormItem,
          {
            label: 'Okta password',
            name: 'authentication.password',
            componentType: FormComponentType.INPUT_TEXT,
            startVersion: SnowflakePluginVersions.V7,
            dataType: InputDataType.PASSWORD,
            rules: [{ required: true, message: 'Okta password is required' }],
            display: {
              show: {
                connectionType: ['okta']
              }
            }
          },
          {
            label: 'Authenticator URL',
            name: 'okta.authenticatorUrl',
            componentType: FormComponentType.INPUT_TEXT,
            startVersion: SnowflakePluginVersions.V7,
            rules: [{ required: true, message: 'Authenticator URL is required' }],
            placeholder: 'https://myaccount.okta.com',
            display: {
              show: {
                connectionType: ['okta']
              }
            }
          },
          {
            label: '',
            messageTemplate: 'SSO that requires 2FA is not supported at this time.',
            name: 'oauth-binding-alert',
            startVersion: SnowflakePluginVersions.V7,
            componentType: FormComponentType.ALERT,
            type: 'warning',
            display: {
              show: {
                connectionType: ['okta']
              }
            }
          },
          // DATABASE NAME
          { ...BASE_DATABASE_NAME, startVersion: SnowflakePluginVersions.V1, endVersion: SnowflakePluginVersions.V6 } as FormItem,
          {
            ...BASE_DATABASE_NAME,
            startVersion: SnowflakePluginVersions.V7,
            display: {
              show: {
                connectionType: ['fields']
              }
            }
          } as FormItem,
          // DATABASE WAREHOUSE
          { ...BASE_DATABASE_WAREHOUSE, startVersion: SnowflakePluginVersions.V1, endVersion: SnowflakePluginVersions.V6 } as FormItem,
          {
            ...BASE_DATABASE_WAREHOUSE,
            startVersion: SnowflakePluginVersions.V7,
            display: {
              show: {
                connectionType: ['fields']
              }
            }
          } as FormItem,
          // DATABASE SCHEMA
          { ...BASE_DATABASE_SCHEMA, startVersion: SnowflakePluginVersions.V1, endVersion: SnowflakePluginVersions.V7 } as FormItem,
          {
            ...BASE_DATABASE_SCHEMA,
            startVersion: SnowflakePluginVersions.V7,
            display: {
              show: {
                connectionType: ['fields']
              }
            }
          } as FormItem,
          // ROLE
          { ...BASE_ROLE, startVersion: SnowflakePluginVersions.V1, endVersion: SnowflakePluginVersions.V6 } as FormItem,
          {
            ...BASE_ROLE,
            startVersion: SnowflakePluginVersions.V7,
            display: {
              show: {
                connectionType: ['fields']
              }
            }
          } as FormItem
        ]
      }
    ]
  },
  actionTemplate: {
    sections: [
      {
        name: 'main',
        items: [
          {
            label: '', // Query
            name: 'body',
            startVersion: SnowflakePluginVersions.V1,
            componentType: FormComponentType.CODE_EDITOR,
            language: EditorLanguage.SQL,
            initialValue: SQL_INITIAL_TEXT
          }
        ]
      },
      {
        name: 'advanced:main',
        items: [
          {
            label: 'Use parameterized SQL',
            name: 'usePreparedSql',
            startVersion: SnowflakePluginVersions.V6,
            componentType: FormComponentType.SWITCH,
            initialValue: true,
            tooltip: {
              markdownText: PARAMETERIZED_SQL_DESCRIPTION
            }
          }
        ]
      }
    ]
  }
};
