import { generatePath } from "react-router";
import { createSelector } from "reselect";
import type { AppState } from "store/types";

export const getRoutes = (state: AppState) => state.legacy.entities.app.routes;

export const getCurrentRoute = (state: AppState) =>
  state.legacy.entities.app.currentRoute;

export const getRoutesList = createSelector(getRoutes, (routes) => {
  return Object.values(routes ?? {}).sort((a, b) =>
    a.path.localeCompare(b.path),
  );
});

export const getCurrentRoutePath = createSelector(
  getCurrentRoute,
  (currentRoute) => {
    return currentRoute?.routeDef?.path;
  },
);

export const getCurrentRoutePathWithParams = createSelector(
  getCurrentRoute,
  (currentRoute) => {
    // Combine /users/:userId with { userId: 1 } to get /users/1
    return generatePath(
      currentRoute?.routeDef?.path ?? "",
      currentRoute?.params,
    );
  },
);
