import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";

//TODO: create a general datepick component here like recommended single dropdown
export const DatePickerStyle = styleAsClass`
  width: 100%;
  margin-top: 8px !important;
  height: 32px;
  &.ant-picker.ant-picker-disabled {
    background: ${colors.GREY_50};
    border-color: ${colors.GREY_50};
  }
  &.ant-picker.ant-picker-disabled .ant-picker-input > input {
    color: ${colors.GREY_300};
    
  }
  &.ant-picker .ant-picker-input > input {
    font-size: 12px;
  }
`;
