import { produce } from "immer";
import { select } from "redux-saga/effects";
import replayManager from "legacy/workers/replay/ApiDSLReplayManager";
import { EntitiesErrorType } from "store/utils/types";
import { fastClone } from "utils/clone";
import { createSaga, callSagas } from "../../../utils/saga";
import * as BackendTypes from "../backend-types";
import { selectV2ApiById } from "../selectors";
import slice from "../slice";
import { updateV2ApiSaga } from "./updateV2Api";
import type { ApiDtoWithPb } from "store/slices/apisV2/slice";

function* updateV2ApiTriggersInternal(payload: {
  newTrigger: BackendTypes.Trigger;
  apiId: string;
}): Generator<unknown, void, any> {
  const { newTrigger, apiId } = payload;

  const api: ReturnType<typeof selectV2ApiById> = yield select((state) =>
    selectV2ApiById(state, apiId),
  );

  if (api == null || newTrigger == null) {
    return;
  }
  const newApi: ApiDtoWithPb = produce(api, (draft) => {
    draft.apiPb.trigger = newTrigger;
  });
  yield callSagas([updateV2ApiSaga.apply({ apiPb: newApi.apiPb })]);
}

export const updateV2ApiTrigger = createSaga(
  updateV2ApiTriggersInternal,
  "updateV2ApiTriggersSaga",
  {
    sliceName: slice.name,
  },
);

slice.saga(updateV2ApiTrigger, {
  start(state, { payload }) {
    delete state.errors[payload.apiId];
    // add the initial api dsl to the replay state if not already there
    const apiId = payload.apiId;
    replayManager.initializeManager(
      apiId,
      fastClone(state.entities[apiId]?.apiPb),
    );
  },
  success(state, { payload, meta }) {
    // update the replay manager
    if (meta?.args?.apiId) {
      const apiId = meta.args.apiId;
      replayManager.update(apiId, fastClone(state.entities[apiId]?.apiPb));
    }
  },
  error(state, { payload, meta }) {
    state.errors[meta.args.apiId] = {
      type: EntitiesErrorType.SAVE_ERROR,
      error: payload,
    };
  },
});
