import {
  Agent,
  AgentsHealthStatus,
  ApplicationScope,
  AuthConfig,
  AuthType,
  getDisplayName,
  IntegrationAuthType,
  Organization,
  Profile,
  RestApiIntegrationDatasourceConfiguration,
  SupersetIntegrationDto,
  ViewMode,
  WorkflowExecutionParamsKey,
} from "@superblocksteam/shared";
import { produce } from "immer";
import { get, uniq } from "lodash";
import { call, put, race, select, take } from "redux-saga/effects";
import {
  restartEvaluation,
  stopEvaluation,
} from "legacy/actions/evaluationActions";
import { ReduxActionTypes } from "legacy/constants/ReduxActionConstants";
import { Profiles } from "legacy/reducers/entityReducers/appReducer";
import { evaluateActionBindings } from "legacy/sagas/EvaluationsShared";
import {
  getAppProfilesInCurrentMode,
  getAppViewMode,
  getProfileForTest,
} from "legacy/selectors/applicationSelectors";
import { getExistingWidgetNames } from "legacy/selectors/sagaSelectors";

import { getCurrentUserIsAdmin } from "legacy/selectors/usersSelectors";
import { sendUINotification } from "legacy/utils/ApiNotificationUtility";
import { checkAuthV3 } from "store/slices/apisShared/client-auth";
import { selectAllApiUnionNames } from "store/slices/apisShared/selectors";
import { selectPluginDatasources } from "store/slices/datasources";
import { getConfigFromIntegrationWithProfileId } from "store/slices/datasources/utils";
import { Flag, selectFlagById } from "store/slices/featureFlags";
import { EntitiesErrorType } from "store/utils/types";
import { noActiveAgentMessage } from "utils/error/error";
import logger from "utils/logger";
import { sendNoActiveAgentUINotification } from "utils/notification";
import { Action, PayloadActionWithMeta } from "../../../utils/action";
import { callSagas, createSaga } from "../../../utils/saga";

import {
  getAgentsSaga,
  isLoadingAgentsHealth,
  selectActiveAgents,
  selectAgentsHealthStatus,
} from "../../agents";
import { selectOrganizations } from "../../organizations";
import { executeApi } from "../client";
import {
  selectApiById,
  selectApiExtractedBindings,
  selectApiMetaById,
} from "../selectors";
import slice, { ApiMeta } from "../slice";
import {
  ApiDto,
  ApiExecutionResponseDto,
  ApiExecutionSagaPayload,
  ApiTriggerType,
  ExecutionParamDto,
} from "../types";
import { convertValuesToParams, extractAttachedFiles } from "../utils/bindings";
import { decodeBytestringsExecutionContextDto } from "../utils/bytestring";
import { getApiToComponentDepsSaga } from "./getApiToComponentDeps";

import { persistApiSaga } from "./persistApi";

function* requestDatasourceAuth(datasourceId: string) {
  yield put({
    type: ReduxActionTypes.REQUEST_DATASOURCE_AUTH,
    payload: { datasourceId },
  });
}

function* executeApiInternal({
  apiId,
  // Only used if custom profiles are not enabled via the feature flag
  environment,
  eventType,
  viewMode,
  params,
  notifyOnSystemError,
  manualRun,
  callStack,
  spanId,
  commitId,
}: ApiExecutionSagaPayload) {
  // TODO: maybe we should also ship call stacks to the agent
  logger.debug("executeApiInternal", {
    apiId,
    callStack: callStack.map((item) => item.propertyPath).reverse(),
  });

  let meta: ApiMeta | undefined = yield select((state) =>
    selectApiMetaById(state, apiId),
  );

  const organizations: Organization[] = yield select(selectOrganizations);
  const organization = Object.values(organizations)[0];

  while (meta?.dirty || meta?.saving) {
    yield take((action: Action) => {
      if (action.type === persistApiSaga.success.type) {
        const persistSuccessAction = action as PayloadActionWithMeta<{
          api: ApiDto;
          shouldUpdateState: boolean;
        }>;

        return persistSuccessAction.payload.api.id === apiId;
      }

      return false;
    });
    meta = yield select((state) => selectApiMetaById(state, apiId));
  }

  const api: ApiDto = yield select((state) => selectApiById(state, apiId));

  if (!api.actions) {
    return;
  }

  const enableProfiles: boolean = yield select(
    selectFlagById,
    Flag.ENABLE_PROFILES,
  );
  //get profile given mode
  let profile: Profile | undefined;
  let mode: ViewMode | undefined;
  if (api?.actions?.triggerType === ApiTriggerType.UI) {
    // select app profiles if in Application mode
    const profiles: Profiles = yield select(getAppProfilesInCurrentMode);
    profile = profiles?.selected;
    mode = yield select(getAppViewMode);
  } else if (
    api?.actions?.triggerType === ApiTriggerType.SCHEDULE &&
    viewMode
  ) {
    // Leaving mode and profile empty so that it is decided in the backend
    // based on viewMode
  } else {
    // this is only called in workflow/scheduled job edit mode
    profile = yield select(getProfileForTest, apiId);
    mode = ViewMode.EDITOR;
  }

  const profileId =
    organization.profiles?.find((p) => {
      return p.key === (enableProfiles ? profile?.key : environment);
    })?.id ?? "";
  // Set or override the variable `environment` in query params
  params = params.map((param) => {
    if (param.key === WorkflowExecutionParamsKey.QUERY_PARAMS) {
      return {
        key: param.key,
        value: {
          ...(param.value as Record<string, string>),
          ...(enableProfiles ? { profile: profile?.key } : { environment }),
        },
      };
    } else {
      return param;
    }
  });

  let agents: Agent[] = yield select(
    selectActiveAgents(
      organization.agentType,
      enableProfiles ? profile?.key ?? environment : environment,
    ),
  );

  if (!agents.length) {
    const isLoadingAgents: boolean = yield select(isLoadingAgentsHealth);
    if (isLoadingAgents) {
      yield race({
        s: take(getAgentsSaga.success.type),
        f: take(getAgentsSaga.error.type),
      });
    } else {
      yield callSagas([getAgentsSaga.apply({ organization })]);
    }

    // Try again
    agents = yield select(
      selectActiveAgents(
        organization.agentType,
        enableProfiles ? profile?.key ?? environment : environment,
      ),
    );
    // If still no agents, show error
    if (!agents.length) {
      const userIsAdmin: ReturnType<typeof getCurrentUserIsAdmin> =
        yield select(getCurrentUserIsAdmin);
      const agentsHealthStatus: AgentsHealthStatus = yield select(
        selectAgentsHealthStatus(
          organization.agentType,
          enableProfiles ? profileId ?? environment : environment,
        ),
      );

      sendNoActiveAgentUINotification({
        organizationAgentType: organization.agentType,
        userIsAdmin,
        agentsHealthStatus,
        profile,
      });
      return {
        systemError: noActiveAgentMessage(organization.agentType),
      } as ApiExecutionResponseDto;
    }
  }

  const current = (yield select(selectApiById, api.id)) as ApiDto;
  const datasources = (yield select(selectPluginDatasources)) as Record<
    string,
    SupersetIntegrationDto
  >;
  const widgetNames: string[] = yield select(getExistingWidgetNames);
  const apiNames: string[] = yield select(selectAllApiUnionNames);

  const allNames = [
    ...widgetNames,
    ...apiNames.filter((name) => name !== current?.actions?.name),
  ];

  if (allNames.includes(api?.actions?.name)) {
    throw new Error(`${api?.actions?.name} already exists.`);
  }

  const datasourceIds = Object.values(api.actions.actions).map(
    (action) => action.datasourceId,
  );

  // feAuthFlows lists the auth types that may require a UI flow during
  // execution.
  const feAuthFlows: AuthType[] = [
    IntegrationAuthType.BASIC,
    IntegrationAuthType.FIREBASE,
    IntegrationAuthType.OAUTH2_PASSWORD,
    IntegrationAuthType.OAUTH2_IMPLICIT,
    IntegrationAuthType.OAUTH2_CODE,
  ];

  const authWithFe = (authType: AuthType, authConfig: AuthConfig): boolean => {
    if (!feAuthFlows.includes(authType)) {
      return false;
    }
    if (
      authType === IntegrationAuthType.OAUTH2_CODE &&
      authConfig?.refreshTokenFromServer
    ) {
      // We login with Google on the integration page.
      // TODO: We should add a more general flag to the OAuth code to store if
      // we expect to login on the integration page or in the action itself.
      return false;
    }
    return true;
  };

  const feAuthedDatasourceIds = Object.values(datasources)
    .filter((datasource) => {
      const currentConfiguration:
        | RestApiIntegrationDatasourceConfiguration
        | undefined = getConfigFromIntegrationWithProfileId(
        datasource,
        profileId,
      );

      const authType = currentConfiguration?.authType;
      const authConfig = currentConfiguration?.authConfig;
      if (!authType || !authConfig) {
        return false;
      }

      if (
        (authType === IntegrationAuthType.OAUTH2_PASSWORD &&
          get(currentConfiguration, "authConfig.useFixedPasswordCreds")) ||
        (authType === IntegrationAuthType.BASIC &&
          get(currentConfiguration, "authConfig.shareBasicAuthCreds"))
      ) {
        // No need to request creds if they're already specified.
        return false;
      }

      return (
        datasourceIds.includes(datasource.id) &&
        authType &&
        authWithFe(authType, authConfig)
      );
    })
    .map((datasource) => datasource.id);

  while (feAuthedDatasourceIds.length > 0) {
    const id = feAuthedDatasourceIds[feAuthedDatasourceIds.length - 1];
    feAuthedDatasourceIds.pop();
    const apiDatasources = Object.values(datasources);
    const foundDatasource = apiDatasources.find(
      (datasource) => datasource.id === id,
    );
    if (!foundDatasource) {
      logger.warn(
        `Unexpectedly did not find datasource ${id} in set of APIs datasources: ${apiDatasources.map(
          (ds) => ds.id,
        )}`,
      );
      continue;
    }
    const currentConfiguration:
      | RestApiIntegrationDatasourceConfiguration
      | undefined = getConfigFromIntegrationWithProfileId(
      foundDatasource,
      profileId,
    );

    const authType = currentConfiguration?.authType as IntegrationAuthType;
    if (!authType) {
      logger.warn(
        `Unexpectedly attempting to authenticate datasource ${id} with no auth type`,
      );
      continue;
    }

    // Check that the current API is a UI api.
    if (api?.actions?.triggerType !== ApiTriggerType.UI) {
      return {
        systemError: `Authentication method "${getDisplayName(
          authType,
        )}" needs access to a browser and is not supported in Workflows or Scheduled Jobs.
Please choose another authentication method, or use in an Application instead.`,
      } as ApiExecutionResponseDto;
    }

    // TODO this is inefficient. LoginModal checks makes this same api call again.
    // This is not so bad for now though since it will only have one duplicated
    // api call while the user is not yet authed.
    const isAuthenticated: { authenticated: boolean } = yield call(
      checkAuthV3,
      {
        orchestrator: false,
        agents,
        organization,
        body: {
          authType: authType,
          datasourceId: id,
          environment: enableProfiles ? profile?.key : environment,
        },
        ...(enableProfiles ? { profile } : {}),
        mode,
      },
    );
    if (!isAuthenticated.authenticated) {
      yield call(requestDatasourceAuth, id);
      yield take(ReduxActionTypes.AUTH_FINISHED);
    }
  }

  let dynamicParams: ExecutionParamDto[] = [];
  let attachedFiles: Record<string, File> = {};
  if (api?.actions?.triggerType === ApiTriggerType.UI) {
    yield callSagas([
      getApiToComponentDepsSaga.apply({ apiIdsToAnalyze: [apiId] }),
    ]);
    const allBindings: Record<
      string,
      {
        id: string;
        name: string;
        bindings: string[] | undefined;
      }
    > = yield select(selectApiExtractedBindings);
    const potentialBinding = allBindings[api.id];
    let bindings: string[] = [];
    if (!potentialBinding || !potentialBinding.bindings) {
      bindings = [];
    } else {
      bindings = uniq(potentialBinding.bindings);
    }

    // Manually add Global and theme objects
    bindings.push("Global");
    bindings.push("theme");
    // NB: We do not want to send the entire icons object to the backend
    // on any API execution as it is too large (3200+ icons) and not needed.
    bindings = bindings.filter((binding) => binding !== "icons");

    const values: unknown[] = yield call(
      evaluateActionBindings,
      bindings,
      ApplicationScope.PAGE, // TODO(API_SCOPE)
    );

    dynamicParams = convertValuesToParams(bindings, values);

    // transform a value of type Uint8Array to
    // new_value = {
    //    "type": "Buffer",
    //    "data": [32, 23, 23, ...]
    // }
    // this is the proper schema of a node.js Buffer
    Object.values(dynamicParams).every((entry) => {
      if (entry.value instanceof Uint8Array) {
        entry.value = {
          type: "Buffer",
          data: Array.from(entry.value),
        };
      }
      return entry;
    });

    attachedFiles = yield call(extractAttachedFiles, dynamicParams);
  }

  const result: ApiExecutionResponseDto = yield call(executeApi, {
    agents,
    body: {
      apiId,
      apiName: api?.actions?.name,
      viewMode,
      params: dynamicParams.concat(params ?? []),
    },
    environment: enableProfiles ? profile?.key : environment,
    eventType,
    ...(enableProfiles ? { profile } : {}),
    notifyOnSystemError,
    organization,
    files: attachedFiles,
    spanId,
    mode,
    commitId,
  });
  decodeBytestringsExecutionContextDto(result.context);

  // If the user manually kicked off this API run (ie by clicking a button),
  // they will have a dedicated UI to see the response of the API so showing an
  // alert is redundant and annoying.
  if (!manualRun) {
    sendUINotification(result);
  }
  return result;
}

export const executeApiSaga = createSaga(executeApiInternal, "executeApiSaga", {
  sliceName: "apis",
  keySelector: (payload) => payload.apiId,
  cancelledBy: [stopEvaluation.type, restartEvaluation.type],
});

slice.saga(executeApiSaga, {
  start(state, { payload }) {
    state.meta[payload.apiId] = state.meta[payload.apiId] ?? {};
    state.meta[payload.apiId].cancelled = false;
    const startingRuns = state.meta[payload.apiId].concurrentRuns ?? 0;
    state.meta[payload.apiId].concurrentRuns = startingRuns + 1;
    state.loading[payload.apiId] = true;
    if (state.errors[payload.apiId]?.type === EntitiesErrorType.EXECUTE_ERROR) {
      delete state.errors[payload.apiId];
    }
  },
  success(state, { payload, meta }) {
    state.meta[meta.args.apiId] = state.meta[meta.args.apiId] ?? {};
    state.meta[meta.args.apiId].executionResult = transformPayload(payload);

    const startingRuns = state.meta[meta.args.apiId].concurrentRuns ?? 0;
    state.meta[meta.args.apiId].concurrentRuns = startingRuns - 1;
    state.meta[meta.args.apiId].waitingForEvaluationSince =
      new Date().toString();
    delete state.loading[meta.args.apiId];
  },
  error(state, { payload, meta }) {
    state.errors[meta.args.apiId] = {
      error: payload,
      type: EntitiesErrorType.EXECUTE_ERROR,
    };
    const apiMeta = state.meta[meta.args.apiId];
    const startingRuns = apiMeta?.concurrentRuns ?? 0;
    if (apiMeta) {
      apiMeta.concurrentRuns = startingRuns - 1;
      apiMeta.waitingForEvaluationSince = new Date().toString();
    }
    delete state.loading[meta.args.apiId];
  },
  cancel(state, { payload }) {
    const apiMeta = state.meta[payload.apiId];
    if (apiMeta) {
      apiMeta.cancelled = true;
      apiMeta.concurrentRuns = 0;
      delete apiMeta.waitingForEvaluationSince;
    }
    delete state.loading[payload.apiId];
  },
});

/**
 * The function `transformPayload` takes in a payload object and modifies it by replacing any error
 * messages with a standardized error object. The current DTO has been modified to include a
 * standardized error object, but the OLD API still returns the old error format.
 */
function transformPayload(payload: ApiExecutionResponseDto | undefined) {
  return produce(payload, (draftPayload) => {
    if (draftPayload?.context?.outputs) {
      for (const [k, o] of Object.entries(draftPayload.context.outputs)) {
        if (o.error) {
          const message = o.error as unknown as string;
          draftPayload.context.outputs[k].error = {
            message,
            fullErrorMessage: message,
            formPath: "",
          };
        }
      }
    }
  });
}
