import { DatasourceMetadataDto } from "@superblocksteam/shared";
import React, { useCallback, useMemo } from "react";

import { SyntaxType } from "code-formatting/constants";
import { useFeatureFlag } from "hooks/ui";
import { Flag } from "store/slices/featureFlags";
import AiBaseFlow from "./AiAssistantBaseFlow";

import { AiAssistantOptionType, FlowType, ScopeType } from "./constants";
import {
  generateRequestBodyForTask,
  getEditorModeForSyntax,
  getTitleForOption,
} from "./util";

const AiGenerateFlow = (props: {
  onConfirm: (
    aiAssistantOptionType: AiAssistantOptionType,
    code?: string,
  ) => void;
  onClose: () => void;
  option: AiAssistantOptionType;
  integrationId?: string;
  configurationId?: string;
  initialConfig?: Record<string, unknown>;
  datasourceMeta?: DatasourceMetadataDto;
  scope: ScopeType;
}) => {
  const {
    onConfirm,
    onClose,
    option,
    integrationId,
    configurationId,
    initialConfig,
    datasourceMeta,
    scope,
  } = props;

  const editorMode = getEditorModeForSyntax(option.syntax);
  const aiMetadataEnabled = useFeatureFlag(Flag.ENABLE_AI_ASSISTANT_METADATA);

  const getRequestBody = useCallback(
    (userInput: string) => {
      return generateRequestBodyForTask({
        userInput,
        syntax: option.syntax,
        flowType: FlowType.GENERATE,
        integrationId,
        configurationId,
        datasourceMeta,
        metadataEnabled: aiMetadataEnabled,
      });
    },
    [
      option.syntax,
      integrationId,
      configurationId,
      datasourceMeta,
      aiMetadataEnabled,
    ],
  );

  const title = useMemo(() => `${getTitleForOption(option)}`, [option]);
  const placeholder = useMemo(() => {
    switch (option.syntax) {
      case SyntaxType.PYTHON:
      case SyntaxType.JAVASCRIPT:
      case SyntaxType.BINDING:
        return "i.e. reverse a list";
      case SyntaxType.REST_API:
        if (scope === ScopeType.PLUGIN) {
          return "i.e. top deals from Salesforce by revenue";
        } else {
          return "i.e. get all users";
        }
      default:
        return "i.e. get all users";
    }
  }, [option, scope]);

  return (
    <AiBaseFlow
      onConfirm={onConfirm}
      onClose={onClose}
      option={option}
      getRequestBody={getRequestBody}
      editorMode={editorMode}
      title={title}
      placeholder={placeholder}
      responseViewType={scope === ScopeType.PLUGIN ? "parsed_json" : "code"}
      initialConfig={initialConfig}
    />
  );
};

export default AiGenerateFlow;
