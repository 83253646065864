import { EXECUTION_PARAM_KEY } from "./ActionConstants";
import type {
  DataTree,
  DataTreeEntity,
} from "legacy/entities/DataTree/dataTreeFactory";

// Always add a validator function in ./Validators for these types
export const VALIDATION_TYPES = {
  TEXT: "TEXT",
  TEXT_EMPTY_NULL: "TEXT_EMPTY_NULL",
  REGEX: "REGEX",
  NUMBER: "NUMBER",
  BOOLEAN: "BOOLEAN",
  OBJECT: "OBJECT",
  OBJECT_OR_UNDEFINED: "OBJECT_OR_UNDEFINED",
  ARRAY: "ARRAY",
  TABLE_DATA: "TABLE_DATA",
  TABLE_SORT: "TABLE_SORT",
  FILTERS_DATA: "FILTERS_DATA",
  OPTIONS_DATA: "OPTIONS_DATA",
  DATE: "DATE",
  DEFAULT_DATE: "DEFAULT_DATE",
  MIN_DATE: "MIN_DATE",
  MAX_DATE: "MAX_DATE",
  TABS_DATA: "TABS_DATA",
  CHART_DATA: "CHART_DATA",
  MARKERS: "MARKERS",
  SELECTED_TAB: "SELECTED_TAB",
  DEFAULT_OPTION_VALUE: "DEFAULT_OPTION_VALUE",
  DEFAULT_SELECTED_ROW: "DEFAULT_SELECTED_ROW",
  COLUMN_PROPERTIES_ARRAY: "COLUMN_PROPERTIES_ARRAY",
  LAT_LONG: "LAT_LONG",
  PLOTLY_CHART_JSON: "PLOTLY_CHART_JSON",
  URL_OR_BASE64: "URL_OR_BASE64",
  FRACTION_DIGITS: "FRACTION_DIGITS",
  NUMBER_ARRAY: "NUMBER_ARRAY",
  NUMBER_ALLOW_UNDEFINED: "NUMBER_ALLOW_UNDEFINED",
  ICONS: "ICONS",
  ANY: "ANY",
};

export type ValidationResponse = {
  isValid: boolean;
  parsed: any;
  message?: string;
  transformed?: any;
};

export type ValidationType =
  (typeof VALIDATION_TYPES)[keyof typeof VALIDATION_TYPES];
export type Validator = (
  value: any,
  props: DataTreeEntity,
  dataTree?: DataTree,
) => ValidationResponse;

export const ISO_DATE_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSSSZ";

export const JAVASCRIPT_KEYWORDS = [
  "abstract",
  "and",
  "await",
  "boolean",
  "break",
  "byte",
  "case",
  "catch",
  "char",
  "class",
  "const",
  "continue",
  "debugger",
  "default",
  "delete",
  "do",
  "double",
  "else",
  "enum",
  "export",
  "extends",
  "false",
  "final",
  "finally",
  "float",
  "for",
  "function",
  "goto",
  "if",
  "implements",
  "import",
  "in",
  "instanceof",
  "int",
  "interface",
  "let",
  "long",
  "native",
  "new",
  "null",
  "package",
  "private",
  "protected",
  "public",
  "return",
  "short",
  "static",
  "super",
  "switch",
  "synchronized",
  "this",
  "throw",
  "throws",
  "transient",
  "true",
  "try",
  "typeof",
  "var",
  "void",
  "volatile",
  "while",
  "with",
  "yield",
];

export const JAVASCRIPT_GLOBALS = [
  "VMError",
  "Buffer",
  "setTimeout",
  "setInterval",
  "setImmediate",
  "clearTimeout",
  "clearInterval",
  "clearImmediate",
  "process",
  "console",
  "Global",
  "Env",
  "$superblocksFiles",
  "Object",
  "Function",
  "Array",
  "Number",
  "parseFloat",
  "parseInt",
  "Infinity",
  "NaN",
  "undefined",
  "Boolean",
  "String",
  "Symbol",
  "Date",
  "Promise",
  "RegExp",
  "Error",
  "EvalError",
  "RangeError",
  "ReferenceError",
  "SyntaxError",
  "TypeError",
  "URIError",
  "globalThis",
  "JSON",
  "Math",
  "Intl",
  "ArrayBuffer",
  "Uint8Array",
  "Int8Array",
  "Uint16Array",
  "Int16Array",
  "Uint32Array",
  "Int32Array",
  "Float32Array",
  "Float64Array",
  "Uint8ClampedArray",
  "BigUint64Array",
  "BigInt64Array",
  "DataView",
  "Map",
  "BigInt",
  "Set",
  "WeakMap",
  "WeakSet",
  "Proxy",
  "Reflect",
  "decodeURI",
  "decodeURIComponent",
  "encodeURI",
  "encodeURIComponent",
  "escape",
  "unescape",
  "eval",
  "isFinite",
  "isNaN",
  "SharedArrayBuffer",
  "Atomics",
  "FinalizationRegistry",
  "WeakRef",
  "WebAssembly",
];

export const PYTHON_KEYWORDS = [
  "False",
  "None",
  "True",
  "and",
  "as",
  "assert",
  "async",
  "await",
  "break",
  "class",
  "continue",
  "def",
  "del",
  "elif",
  "else",
  "except",
  "finally",
  "for",
  "from",
  "global",
  "if",
  "import",
  "in",
  "is",
  "lambda",
  "nonlocal",
  "not",
  "or",
  "pass",
  "raise",
  "return",
  "try",
  "while",
  "with",
  "yield",
];

export const PYTHON_GLOBALS = [
  "__builtins__",
  "__debug__",
  "__doc__",
  "__import__",
  "__loader__",
  "__name__",
  "__package__",
  "__spec__",
  "$superblocksFiles",
  "Ellipsis",
  "Env",
  "Global",
  "NotImplemented",
  "SimpleNamespace",
  "abs",
  "all",
  "any",
  "ascii",
  "base64",
  "bin",
  "bool",
  "bytearray",
  "bytes",
  "callable",
  "chr",
  "classmethod",
  "compile",
  "complex",
  "delattr",
  "dict",
  "dir",
  "divmod",
  "enumerate",
  "exec",
  "eval",
  "filter",
  "float",
  "format",
  "frozenset",
  "getattr",
  "globals",
  "hasattr",
  "hash",
  "help",
  "hex",
  "id",
  "input",
  "int",
  "isinstance",
  "issubclass",
  "iter",
  "len",
  "list",
  "locals",
  "map",
  "max",
  "memoryview",
  "min",
  "next",
  "object",
  "oct",
  "open",
  "ord",
  "pow",
  "partial",
  "print",
  "property",
  "range",
  "repr",
  "reversed",
  "round",
  "set",
  "setattr",
  "slice",
  "sorted",
  "staticmethod",
  "str",
  "sum",
  "super",
  "superblocksReader",
  "tuple",
  "type",
  "vars",
  "wrapper",
  "zip",
];

export const DATA_TREE_KEYWORDS = [
  "App",
  "globalVariables",
  "pageList",
  "Embed",
  "theme",
  EXECUTION_PARAM_KEY,
];

export const BASE_WIDGET_VALIDATION = {
  isLoading: VALIDATION_TYPES.BOOLEAN,
  isVisible: VALIDATION_TYPES.BOOLEAN,
  isDisabled: VALIDATION_TYPES.BOOLEAN,
};

export type WidgetPropertyValidationType = Record<
  string,
  ValidationType | Validator
>;
