import {
  DB_SQL_INITIAL_TEXT,
  EditorLanguage,
  FormComponentType,
  FormItem,
  FormRow,
  InputDataType,
  Plugin,
  PluginResponseType,
  PluginType
} from '../../types';
import { sshTunnelSections } from './shared';
import { CONNECTION_METHODS_AND_DISPLAY_NAMES, makeDropdownItem } from './shared/db';

export const CouchbasePluginVersions = {
  V1: '0.0.1',
  V2: '0.0.2'
};

const formFields: (FormItem | FormRow)[] = [
  {
    label: 'Host URL',
    name: 'endpoint.host',
    startVersion: CouchbasePluginVersions.V1,
    componentType: FormComponentType.INPUT_TEXT,
    placeholder: 'sample-api.couchbase.com',
    rules: [{ required: true, message: 'Host URL is required' }]
  },
  {
    label: 'Port',
    name: 'endpoint.port',
    startVersion: CouchbasePluginVersions.V1,
    componentType: FormComponentType.INPUT_TEXT,
    placeholder: '11210',
    initialValue: 11210,
    dataType: InputDataType.NUMBER,
    rules: [{ required: true, message: 'Port is required' }]
  },
  {
    label: 'Bucket',
    name: 'connection.bucket',
    placeholder: 'bucket',
    startVersion: CouchbasePluginVersions.V1,
    componentType: FormComponentType.INPUT_TEXT,
    rules: [{ required: true, message: 'Bucket name is required' }]
  },
  {
    label: 'Use TLS',
    name: 'connection.useTls',
    startVersion: CouchbasePluginVersions.V1,
    componentType: FormComponentType.CHECKBOX
  }
];

formFields.forEach((field) => {
  field['display'] = {
    show: {
      connectionType: ['fields']
    }
  };
});

const connectionUrlFields: (FormItem | FormRow)[] = [
  {
    label: 'URI',
    name: 'connection.url',
    startVersion: CouchbasePluginVersions.V2,
    componentType: FormComponentType.INPUT_TEXT,
    dataType: InputDataType.PASSWORD, // since this can sometimes contain a password
    placeholder: `couchbase[s]://my-host-name:11210,my-host-name:8091?[network=external]&[operation_timeout=10.0]`,
    tooltip: {
      markdownText: '[Read the docs](https://docs.couchbase.com/nodejs-sdk/current/howtos/managing-connections.html)'
    },
    rules: [{ required: true, message: 'URI is required' }]
  }
];

connectionUrlFields.forEach((field) => {
  field['display'] = {
    show: {
      connectionType: ['url']
    }
  };
});

export const CouchbasePlugin: Plugin = {
  id: 'couchbase',
  name: 'Couchbase',
  moduleName: 'CouchbasePlugin',
  modulePath: 'plugins/couchbase/CouchbasePlugin',
  iconLocation: 'https://superblocks.s3-us-west-2.amazonaws.com/img/integrations/couchbase.png',
  docsUrl: 'https://docs.superblocks.com/integrations/integrations-library/couchbase',
  type: PluginType.API,
  responseType: PluginResponseType.JSON,
  hasRawRequest: true,
  hasMetadata: true,
  datasourceTemplate: {
    sections: [
      {
        name: 'main',
        items: [
          {
            label: 'Display name',
            name: 'name',
            startVersion: CouchbasePluginVersions.V1,
            componentType: FormComponentType.INPUT_TEXT,
            placeholder: 'ProdDB',
            rules: [{ required: true, message: 'Display name is required' }]
          },
          {
            label: 'Username',
            name: 'connection.user',
            startVersion: CouchbasePluginVersions.V1,
            componentType: FormComponentType.INPUT_TEXT,
            placeholder: 'user',
            rules: [{ required: true, message: 'Username is required' }]
          },
          {
            label: 'Password',
            name: 'connection.password',
            startVersion: CouchbasePluginVersions.V1,
            componentType: FormComponentType.INPUT_TEXT,
            dataType: InputDataType.PASSWORD,
            placeholder: 'password',
            rules: [{ required: true, message: 'Password is required' }]
          },
          {
            label: 'Connection method',
            name: 'connectionType',
            startVersion: CouchbasePluginVersions.V2,
            componentType: FormComponentType.DROPDOWN,
            initialValue: 'fields',
            rules: [{ required: true }],
            options: Object.entries(CONNECTION_METHODS_AND_DISPLAY_NAMES).map(([value, displayName]) =>
              makeDropdownItem(value, displayName)
            )
          },
          ...formFields,
          ...connectionUrlFields,
          ...sshTunnelSections({
            startVersion: CouchbasePluginVersions.V1,
            isProto: true
          })
        ]
      }
    ]
  },
  actionTemplate: {
    sections: [
      {
        name: 'main',
        items: [
          {
            label: 'Action',
            name: 'couchbaseAction.case',
            startVersion: CouchbasePluginVersions.V1,
            componentType: FormComponentType.DROPDOWN,
            initialValue: 'runSql',
            rules: [{ required: true }],
            options: [
              {
                key: 'runSql',
                value: 'runSql',
                displayName: 'Query'
              },
              {
                key: 'insert',
                value: 'insert',
                displayName: 'Insert item'
              },
              {
                key: 'get',
                value: 'get',
                displayName: 'Get item'
              },
              {
                key: 'remove',
                value: 'remove',
                displayName: 'Remove item'
              }
            ]
          },
          {
            label: 'Scope',
            name: 'couchbaseAction.value.identifier.scope',
            startVersion: CouchbasePluginVersions.V1,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            initialValue: '',
            placeholder: 'scope',
            display: {
              show: {
                'couchbaseAction.case': ['insert', 'get', 'remove']
              }
            }
          },
          {
            label: 'Collection',
            name: 'couchbaseAction.value.identifier.collection',
            startVersion: CouchbasePluginVersions.V1,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            initialValue: '',
            placeholder: 'collection',
            display: {
              show: {
                'couchbaseAction.case': ['insert', 'get', 'remove']
              }
            }
          },
          {
            label: '', // Query
            name: 'couchbaseAction.value.sqlBody',
            startVersion: CouchbasePluginVersions.V1,
            componentType: FormComponentType.CODE_EDITOR,
            language: EditorLanguage.SQL,
            initialValue: DB_SQL_INITIAL_TEXT,
            display: {
              show: {
                'couchbaseAction.case': ['runSql']
              }
            }
          },
          {
            label: 'Key',
            name: 'couchbaseAction.value.key',
            startVersion: CouchbasePluginVersions.V1,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            initialValue: '',
            placeholder: 'key',
            display: {
              show: {
                'couchbaseAction.case': ['insert', 'get', 'remove']
              }
            }
          },
          {
            label: 'Document',
            name: 'couchbaseAction.value.value',
            startVersion: CouchbasePluginVersions.V1,
            componentType: FormComponentType.CODE_EDITOR,
            language: EditorLanguage.JSON,
            initialValue: '',
            placeholder: '',
            display: {
              show: {
                'couchbaseAction.case': ['insert']
              }
            }
          }
        ]
      }
    ]
  }
};
