import { Profile } from "@superblocksteam/shared";
import { isEmpty } from "lodash";

// used to render configuration navigation in the integration page
export type ConfigMeta = {
  id: string;
  isDefault: boolean;
  profileIds: string[];
  created: Date;
  originalProfileIds: string[];
};

export const generateEmptyConfigTooltipText = (
  configMeta: ConfigMeta,
  profiles: Profile[],
) => {
  const originProfileNames = profiles
    .filter((profile) => configMeta.originalProfileIds.includes(profile.id))
    .map((profile) => profile.displayName)
    .join(", ");

  return `${
    !isEmpty(configMeta.originalProfileIds)
      ? `**${originProfileNames}** no longer ${
          configMeta.originalProfileIds.length > 1 ? "use" : "uses"
        } this configuration`
      : "No profiles use this configuration"
  }.${configMeta.isDefault ? "" : " Config will be deleted when you save."}`;
};

export enum IntegrationPageTabKeys {
  Configurations = "Configurations",
  OpenApiSpec = "OpenApiSpec",
}
