import Icon from "@ant-design/icons";
import {
  ButtonType,
  ButtonClickPayload,
  LoadButtonPayloadInit,
  ExtraValues,
} from "@superblocksteam/shared";
import { set } from "lodash";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Unsubscribe } from "redux";
import styled from "styled-components";
import { SecondaryButton } from "components/ui/Button";
import {
  dynamicFormButtonClick,
  loadDynamicFormButtonInit,
} from "legacy/actions/dynamicFormActions";
import { getDynamicFormState } from "legacy/selectors/dynamicFormSelectors";
import { useAppDispatch } from "store/helpers";
import { DynamicFormItemProps } from "../DynamicFormItem";

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const Button = styled(SecondaryButton)<{ hasIcon?: boolean }>`
  padding: ${(props) => (props.hasIcon ? "8px 12px 8px 10px" : "8px 10px")};
  font-size: 12px;
  img {
    width: 16px;
    height: 16px;
  }
  .ant-space-horizontal {
    gap: 6px;
  }
  &.ant-btn[disabled],
  .ant-btn[disabled]:hover,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:active {
    color: ${(props) => props.theme.colors.GREY_300};
    background: ${(props) => props.theme.colors.GREY_50};
    border-color: ${(props) => props.theme.colors.GREY_50};
  }
`;

interface ButtonProps {
  iconUrl?: string;
  integrationId?: string;
  configurationId?: string;
  getValueFromContext: (path: string) => unknown;
  buttonType: ButtonType;
  valuesFromContext?: string[];
  extraValues?: ExtraValues;
  dependencies?: string[];
  subscribe?: <T = unknown>(
    path: string,
    callback: (value: T) => void,
  ) => Unsubscribe;
}

type Props = ButtonProps & DynamicFormItemProps;

const DynamicFormButton = ({
  path,
  iconUrl,
  getValueFromContext,
  dependencies,
  subscribe,
  buttonType,
  valuesFromContext,
  extraValues,
  label,
  integrationId,
  configurationId,
}: Props) => {
  const dispatch = useAppDispatch();
  const dynamicFormState = useSelector(getDynamicFormState);

  useEffect(() => {
    if (!integrationId || !configurationId) {
      return;
    }
    const loadButtonPayloadInit: LoadButtonPayloadInit = {
      buttonType: buttonType,
      eventAttributes: {
        integrationId: integrationId,
        integrationConfigurationId: configurationId,
      },
    };
    if (valuesFromContext) {
      valuesFromContext.forEach((value) => {
        set(
          loadButtonPayloadInit,
          `eventAttributes.${value}`,
          getValueFromContext(value),
        );
      });
    }
    dispatch(loadDynamicFormButtonInit(loadButtonPayloadInit));
  }, [
    integrationId,
    configurationId,
    buttonType,
    dispatch,
    getValueFromContext,
    valuesFromContext,
  ]);

  useEffect(() => {
    if (!subscribe) {
      return;
    }
    if (!dependencies) {
      return;
    }
    if (!integrationId || !configurationId) {
      return;
    }
    const unsubscribes: Unsubscribe[] = [];
    for (const dependency of dependencies) {
      const unsubscribe = subscribe(dependency, (value) => {
        const loadButtonPayloadInit: LoadButtonPayloadInit = {
          buttonType: buttonType,
          eventAttributes: {
            integrationId: integrationId,
            integrationConfigurationId: configurationId,
          },
        };
        if (valuesFromContext) {
          valuesFromContext.forEach((value) => {
            set(
              loadButtonPayloadInit,
              `eventAttributes.${value}`,
              getValueFromContext(value),
            );
          });
        }
        dispatch(loadDynamicFormButtonInit(loadButtonPayloadInit));
      });
      unsubscribes.push(unsubscribe);
    }
    return () => {
      for (const unsubscribe of unsubscribes) {
        unsubscribe();
      }
    };
  }, [
    subscribe,
    dependencies,
    buttonType,
    configurationId,
    dispatch,
    getValueFromContext,
    integrationId,
    valuesFromContext,
  ]);

  return (
    <ButtonContainer key={"container" || path}>
      <Button
        icon={
          iconUrl && <Icon component={() => <img src={iconUrl} alt="" />} />
        }
        key={path}
        onClick={async () => {
          let clickEventPayload: ButtonClickPayload;
          switch (buttonType) {
            case "connectOAuth":
              clickEventPayload = {
                buttonType: buttonType,
                eventAttributes: {
                  pluginId: extraValues?.pluginId as string,
                  accessType: extraValues?.accessType,
                  responseType: extraValues?.responseType,
                  stateConfigExclude: extraValues?.stateConfigExclude,
                  owner: extraValues?.owner,
                  integrationId: integrationId as string,
                  configurationId: configurationId as string,
                },
              };
              if (valuesFromContext) {
                valuesFromContext.forEach((value) => {
                  set(
                    clickEventPayload,
                    `eventAttributes.${value}`,
                    getValueFromContext(value),
                  );
                });
              }
              dispatch(dynamicFormButtonClick(clickEventPayload));
              break;
            case "revokeOAuthTokens":
              clickEventPayload = {
                buttonType: buttonType,
                eventAttributes: {
                  integrationId: integrationId as string,
                  configurationId: configurationId as string,
                },
              };
              if (valuesFromContext) {
                valuesFromContext.forEach((value) => {
                  set(
                    clickEventPayload,
                    `eventAttributes.${value}`,
                    getValueFromContext(value),
                  );
                });
              }
              dispatch(dynamicFormButtonClick(clickEventPayload));
              break;
            default:
              break;
          }
        }}
        loading={dynamicFormState.dynamicFormButtonLoading[buttonType]}
        disabled={dynamicFormState.dynamicFormButtonDisabled[buttonType]}
        data-test={path}
      >
        {/* <Space align="center" direction="horizontal"> */}
        {label}
        {/* </Space> */}
      </Button>
    </ButtonContainer>
  );
};

export default DynamicFormButton;
