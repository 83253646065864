import { RouteDef } from "@superblocksteam/shared";
import { matchRoutes } from "react-router";
import { getEditorBasePath } from "hooks/store/useGetEditorPath";
import {
  EditorRoute,
  getApplicationDeployedURL,
  getApplicationEmbedPreviewURL,
  getApplicationEmbedURL,
  getApplicationPreviewURL,
} from "legacy/constants/routes";
import { APP_MODE } from "legacy/reducers/types";
import {
  getCurrentQueryParams,
  getSystemQueryParams,
} from "legacy/utils/queryParams";
import { router } from "router";
import { isOnEmbedPreviewRoute, isOnEmbedRoute } from "./embed/messages";
import { extractDynamicSegments, getFinalRoutePath } from "./routing";

export function getTargetNavigationURL({
  targetPath,
  applicationId,
  appMode,
  params = {},
  resetUrlState = false,
}: {
  targetPath: string;
  applicationId: string | undefined;
  params?: Record<string, string>;
  appMode: APP_MODE | undefined;
  resetUrlState?: boolean;
}) {
  let basePath: string;
  if (isOnEmbedPreviewRoute()) {
    basePath = getApplicationEmbedPreviewURL(applicationId);
  } else if (isOnEmbedRoute()) {
    basePath = getApplicationEmbedURL(applicationId);
  } else if (appMode === APP_MODE.EDIT) {
    const matchedRoute = matchRoutes(router.routes, window.location)?.at(-1);

    // legacy behavior, we should always have a matched route, otherwise we really can't do much
    if (!matchedRoute) {
      const baseEditPath = getEditorBasePath(EditorRoute.EditApplication, {
        applicationId,
      });
      const optionalTabsPath =
        window.location.pathname.split(baseEditPath)[1] ?? "";
      basePath = `${baseEditPath}${optionalTabsPath}`;
    } else if (resetUrlState) {
      // when we navigate between pages, we want to reset things like the tab state in the URL
      basePath = getEditorBasePath(EditorRoute.EditApplication, {
        applicationId,
      });
    } else {
      basePath = getEditorBasePath(matchedRoute.route.path as any, {
        ...matchedRoute.params,
        applicationId: applicationId,
      });
    }
  } else if (appMode === APP_MODE.PREVIEW) {
    basePath = getApplicationPreviewURL(applicationId);
  } else {
    basePath = getApplicationDeployedURL(applicationId);
  }

  return buildURL({
    targetPath,
    basePath,
    params,
  });
}

function buildURL({
  targetPath,
  basePath,
  params,
}: {
  targetPath: string;
  basePath?: string;
  params?: Record<string, string>;
}): URL {
  // Within the Superblocks context, we only allow absolute paths _within_ the application
  const relativePath = targetPath.substring(1);

  // ensure we handle the optional relative path correctly
  const base = basePath
    ? basePath.endsWith("/")
      ? basePath
      : basePath + "/"
    : "";

  const url = new URL(relativePath, window.location.origin + base);

  if (params) {
    Object.entries(params).forEach(([key, value]) => {
      url.searchParams.set(key, value);
    });
  }
  url.search = url.searchParams.toString();

  return url;
}

type Result<T, E = Error> = { ok: true; value: T } | { ok: false; error: E };

interface RouteToUrlError {
  missingParams: string[];
}

export function buildUrlForRoute(
  route: RouteDef,
  navigateToRoute: {
    keepQueryParams?: boolean;
    routePathDescriptor?: string;
    routeParams?: Record<string, string>;
    queryParams?: Record<string, string>;
  },
  context?: {
    applicationId: string | undefined;
    appMode: APP_MODE | undefined;
    currentPageId: string | undefined;
  },
): Result<URL, RouteToUrlError> {
  const routeParams = navigateToRoute.routeParams || {};
  const systemQueryParams = getSystemQueryParams();
  let queryParams = {
    ...(navigateToRoute.queryParams || {}),
    ...systemQueryParams,
  };

  if (navigateToRoute.keepQueryParams) {
    const existingQueryParams = getCurrentQueryParams(false);

    queryParams = {
      ...existingQueryParams,
      ...queryParams,
    };
  }

  const dynamicSegments = extractDynamicSegments(route.path);
  const haveAllRouteParams = dynamicSegments.every((segment) =>
    Boolean(routeParams[segment]),
  );

  if (!haveAllRouteParams) {
    const missingParams = dynamicSegments.filter(
      (segment) => !routeParams[segment],
    );

    return {
      ok: false,
      error: {
        missingParams,
      },
    };
  }

  const path = getFinalRoutePath(route.path, routeParams);

  let url: URL;
  if (context) {
    // E.g: localhost:3000/applications/edit/7d6e773d-9fff-4876-86c9-840d82467840/books/5
    const navigationUrlParams: Parameters<typeof getTargetNavigationURL>[0] = {
      applicationId: context.applicationId,
      appMode: context.appMode,
      targetPath: path,
      params: queryParams,
    };
    if (context.appMode === APP_MODE.EDIT) {
      navigationUrlParams.resetUrlState =
        "pageId" in route && context.currentPageId !== route.pageId;
    }

    url = getTargetNavigationURL(navigationUrlParams);
  } else {
    // E.g: localhost:3000/books/5
    url = buildURL({
      targetPath: path,
      params: queryParams,
    });
  }

  return {
    ok: true,
    value: url,
  };
}
