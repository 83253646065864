import { DB_SQL_INITIAL_TEXT, EditorLanguage, FormComponentType, InputDataType, Plugin, PluginResponseType, PluginType } from '../../types';
import { PARAMETERIZED_SQL_DESCRIPTION } from './constants';

export const DatabricksPluginVersions = {
  V1: '0.0.1'
};

export const DatabricksPlugin: Plugin = {
  id: 'databricks',
  name: 'Databricks',
  moduleName: 'DatabricksPlugin',
  modulePath: 'plugins/databricks/DatabricksPlugin',
  iconLocation: 'https://superblocks.s3-us-west-2.amazonaws.com/img/integrations/databricks.png',
  docsUrl: 'https://docs.superblocks.com/integrations/integrations-library/databricks',
  type: PluginType.DB,
  responseType: PluginResponseType.TABLE,
  hasRawRequest: true,
  hasMetadata: true,
  rawRequestName: 'Executed SQL',
  datasourceTemplate: {
    sections: [
      {
        name: 'main',
        items: [
          {
            label: 'Display name',
            name: 'name',
            startVersion: DatabricksPluginVersions.V1,
            componentType: FormComponentType.INPUT_TEXT,
            placeholder: 'ProdDB',
            rules: [{ required: true, message: 'Display name is required' }]
          },
          {
            label: 'Host',
            name: 'connection.hostUrl',
            startVersion: DatabricksPluginVersions.V1,
            componentType: FormComponentType.INPUT_TEXT,
            placeholder: 'warehouse-staging.cloud.databricks.com',
            rules: [{ required: true, message: 'Host is required' }]
          },
          {
            label: 'Port',
            name: 'connection.port',
            startVersion: DatabricksPluginVersions.V1,
            componentType: FormComponentType.INPUT_TEXT,
            dataType: InputDataType.NUMBER,
            initialValue: 443,
            rules: [{ required: true, message: 'Port is required' }]
          },
          {
            label: 'Path',
            name: 'connection.path',
            startVersion: DatabricksPluginVersions.V1,
            componentType: FormComponentType.INPUT_TEXT,
            placeholder: '/sql/1.0/warehouses/warehouse',
            rules: [{ required: true, message: 'Path is required' }]
          },
          {
            label: 'Access token',
            name: 'connection.token',
            startVersion: DatabricksPluginVersions.V1,
            componentType: FormComponentType.INPUT_TEXT,
            dataType: InputDataType.PASSWORD,
            placeholder: 'dapiabcdefghijklmnopqrstuvwxyz123456',
            rules: [{ required: true, message: 'Access token is required' }]
          },
          {
            label: 'Default catalog',
            name: 'connection.defaultCatalog',
            startVersion: DatabricksPluginVersions.V1,
            componentType: FormComponentType.INPUT_TEXT,
            placeholder: 'hive_datastore'
          },
          {
            label: 'Default schema',
            name: 'connection.defaultSchema',
            startVersion: DatabricksPluginVersions.V1,
            componentType: FormComponentType.INPUT_TEXT,
            placeholder: 'default'
          }
        ]
      }
    ]
  },
  actionTemplate: {
    sections: [
      {
        name: 'main',
        items: [
          {
            label: '', // Query
            name: 'runSql.sqlBody',
            startVersion: DatabricksPluginVersions.V1,
            componentType: FormComponentType.CODE_EDITOR,
            language: EditorLanguage.SQL,
            initialValue: DB_SQL_INITIAL_TEXT
          }
        ]
      },
      {
        name: 'advanced:main',
        items: [
          {
            label: 'Use parameterized SQL',
            name: 'runSql.useParameterized',
            startVersion: DatabricksPluginVersions.V1,
            componentType: FormComponentType.SWITCH,
            initialValue: true,
            tooltip: {
              markdownText: PARAMETERIZED_SQL_DESCRIPTION
            }
          }
        ]
      }
    ]
  }
};
