import {
  AuthConfig,
  AuthType,
  ButtonClickPayload,
  ClientAuthMethod,
  DatasourceAuthState,
  IntegrationAuthType,
  LoadButtonPayloadInit,
  OAUTH_CALLBACK_PATH,
  TokenScope,
  getAuthId,
} from "@superblocksteam/shared";
import { isEmpty, get } from "lodash";
import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  loadDynamicFormButtonInit,
  loadDynamicFormButtonSuccess,
} from "legacy/actions/dynamicFormActions";
import {
  ReduxActionTypes,
  ReduxAction,
  ReduxActionErrorTypes,
} from "legacy/constants/ReduxActionConstants";
import {
  LS_FULL_STATE,
  LS_OAUTH_DATASOURCE_KEY,
  LS_OAUTH_ERROR_KEY,
  LS_OAUTH_ONE_TIME_CODE,
  LS_OAUTH_REDIRECT_FINISHED,
} from "pages/components/OAuthRedirectLoginModal";
import { ApiExecutionResponseDto, selectApiMetaById } from "store/slices/apis";
import {
  getConnectedTokensSaga,
  selectHasConnectedTokens,
} from "store/slices/datasources";
import {
  revokeAndDeleteSharedConnectedTokens,
  revokeAndDeleteUserConnectedTokens,
} from "store/slices/datasources/client";
import { callSagas } from "store/utils/saga";
import { generateOneTimeCode } from "utils/crypto";

function* loadButtonSaga(actionPayload: ReduxAction<LoadButtonPayloadInit>) {
  try {
    const integrationConfigurationId =
      actionPayload.payload.eventAttributes.integrationConfigurationId;
    const integrationId = actionPayload.payload.eventAttributes.integrationId;
    const authId = getAuthId(
      actionPayload.payload.eventAttributes.authType,
      actionPayload.payload.eventAttributes.authConfig,
      integrationId,
      integrationConfigurationId,
    );
    const tokenScope = actionPayload.payload.eventAttributes.authConfig
      ?.tokenScope as TokenScope;
    //TODO(alex): IntegrationForm calls getConnectedTokens saga, but there is no guarantee that the tokens are loaded and Redux state is update at this point,
    // so we need to wait for the tokens to be loaded before we can determine if the button should be disabled or not.
    // calling getConnectedTokensSaga here, which is inefficient, but it works for now.
    //yield take(ReduxActionTypes.LOAD_CONNECTED_TOKENS_SUCCESS);
    yield callSagas([
      getConnectedTokensSaga.apply({
        integrationId: integrationId,
        integrationConfigurationId: integrationConfigurationId,
        authId: authId,
        tokenScope: tokenScope,
      }),
    ]);
    const hasConnectedTokens: boolean = yield select(
      selectHasConnectedTokens,
      integrationConfigurationId,
      integrationId,
    );
    switch (actionPayload.payload.buttonType) {
      case "connectOAuth": {
        // if the user has already connected to this integration or the token is not shared, disable the button
        const dynamicFormButtonDisabled =
          hasConnectedTokens || tokenScope === TokenScope.USER;
        yield put(
          loadDynamicFormButtonSuccess({
            buttonType: "connectOAuth",
            disabled: dynamicFormButtonDisabled,
          }),
        );
        break;
      }
      case "revokeOAuthTokens": {
        // no tokens to revoke, disable the button
        const dynamicFormButtonDisabled = !hasConnectedTokens;
        yield put(
          loadDynamicFormButtonSuccess({
            buttonType: "revokeOAuthTokens",
            disabled: dynamicFormButtonDisabled,
          }),
        );
        break;
      }
      default:
        yield put({
          type: ReduxActionErrorTypes.DYNAMIC_FORM_BUTTON_CLICK_ERROR,
          payload: {
            error: new Error(
              `Unknown button type: ${actionPayload.payload.buttonType}`,
            ),
          },
        });
    }
  } catch (error: any) {
    yield put({
      type: ReduxActionErrorTypes.DYNAMIC_FORM_BUTTON_CLICK_ERROR,
      payload: {
        error,
      },
    });
  }
}

function* buttonClickSaga(
  actionPayload: ReduxAction<ButtonClickPayload>,
): Generator<any, any, any> {
  try {
    switch (actionPayload.payload.buttonType) {
      case "revokeOAuthTokens": {
        try {
          const authId = getAuthId(
            actionPayload.payload.eventAttributes.authType,
            actionPayload.payload.eventAttributes.authConfig,
            actionPayload.payload.eventAttributes.integrationId,
            actionPayload.payload.eventAttributes.configurationId,
          );
          const revokeTokenUrl = actionPayload.payload.eventAttributes
            .authConfig?.revokeTokenUrl as string;
          const result: boolean =
            actionPayload.payload.eventAttributes.authConfig?.tokenScope ===
            TokenScope.DATASOURCE
              ? yield call(
                  revokeAndDeleteSharedConnectedTokens,
                  authId,
                  revokeTokenUrl,
                )
              : yield call(
                  revokeAndDeleteUserConnectedTokens,
                  authId,
                  revokeTokenUrl,
                );
          if (!result) {
            throw new Error(
              `Failed to revoke tokens: ${JSON.stringify(result)}`,
            );
          }
          yield put({
            type: ReduxActionTypes.DYNAMIC_FORM_BUTTON_CLICK_SUCCESS,
            payload: actionPayload.payload,
          });
        } catch (error: any) {
          yield put({
            type: ReduxActionErrorTypes.DYNAMIC_FORM_BUTTON_CLICK_ERROR,
            payload: {
              buttonType: actionPayload.payload.buttonType,
              error: `Failed to revoke tokens: ${error?.message}`,
            },
          });
        }
        break;
      }
      case "connectOAuth": {
        try {
          const eventAttributes = actionPayload.payload.eventAttributes;
          const {
            integrationId,
            configurationId,
            pluginId,
            accessType,
            stateConfigExclude,
            responseType,
            owner,
          } = actionPayload.payload.eventAttributes;
          localStorage.setItem(LS_OAUTH_DATASOURCE_KEY, integrationId);
          localStorage.setItem(LS_OAUTH_REDIRECT_FINISHED, "false");
          const tokenUrl = eventAttributes?.authConfig?.tokenUrl;
          const authorizationUrl =
            eventAttributes?.authConfig?.authorizationUrl;
          const userInfo = eventAttributes?.authConfig?.userInfoUrl;
          const dynamicWorkflowConfiguration =
            eventAttributes?.dynamicWorkflowConfiguration;
          const workflowId = dynamicWorkflowConfiguration?.workflowId;
          const workflowResult = yield select((state) =>
            selectApiMetaById(state, workflowId),
          );
          const workflowExecutionResult =
            workflowResult?.executionResult as ApiExecutionResponseDto;
          const fetchCredentialsDynamically =
            dynamicWorkflowConfiguration?.enabled ?? false;
          const clientId = resolveBindingsFromWorkflowIfNeeded(
            eventAttributes?.authConfig?.clientId,
            workflowExecutionResult,
            fetchCredentialsDynamically,
          );
          const clientSecret = resolveBindingsFromWorkflowIfNeeded(
            eventAttributes?.authConfig?.clientSecret,
            workflowExecutionResult,
            fetchCredentialsDynamically,
          );
          const clientAuthMethod =
            eventAttributes?.authConfig?.clientAuthMethod;
          const scope = resolveBindingsFromWorkflowIfNeeded(
            eventAttributes?.authConfig?.scope,
            workflowExecutionResult,
            fetchCredentialsDynamically,
          );
          const refreshTokenFromServer =
            eventAttributes?.authConfig?.refreshTokenFromServer;
          const tokenScope =
            eventAttributes?.authConfig?.tokenScope ?? TokenScope.DATASOURCE;
          const oneTimeCode = generateOneTimeCode();
          localStorage.setItem(LS_OAUTH_ONE_TIME_CODE, oneTimeCode);
          let stateObj: Partial<DatasourceAuthState> = {
            oneTimeCode,
            integrationId,
            useLocalStorage: true,
          };

          const dsConfigState = {
            authConfig: {
              refreshTokenFromServer: refreshTokenFromServer,
              clientId: clientId as string,
              clientSecret: clientSecret as string,
              clientAuthMethod: clientAuthMethod as ClientAuthMethod,
              tokenUrl: tokenUrl as string,
              authorizationUrl: authorizationUrl as string,
              userInfoUrl: userInfo as string,
              tokenScope,
              scope: scope as string,
            },
            authType: IntegrationAuthType.OAUTH2_CODE,
            authId: getAuthId(
              IntegrationAuthType.OAUTH2_CODE,
              eventAttributes?.authConfig,
              integrationId,
              configurationId,
            ),
            pluginId,
            origin: window.location.origin,
            integrationId,
            configurationId,
          };

          const fullState = { ...stateObj, ...dsConfigState };
          if (!stateConfigExclude?.includes("datasource-auth-state")) {
            // Add the auth config metadata if it is not excluded.
            stateObj = {
              ...stateObj,
              ...dsConfigState,
            };
          }
          const jsonString = JSON.stringify(stateObj);
          const buffer = Buffer.from(jsonString, "utf-8");
          const state = buffer.toString("base64");

          const fullStateJsonString = JSON.stringify(fullState);
          const fullStateBuffer = Buffer.from(fullStateJsonString, "utf-8");
          const fullStateString = fullStateBuffer.toString("base64");

          localStorage.setItem(LS_FULL_STATE, fullStateString);
          let href = `${authorizationUrl}?client_id=${clientId}&scope=${scope}&redirect_uri=${window.location.origin}/${OAUTH_CALLBACK_PATH}`;
          if (state) {
            href = `${href}&state=${state}`;
          }
          if (accessType) {
            href = `${href}&access_type=${accessType}`;
          }
          if (responseType) {
            href = `${href}&response_type=${responseType}`;
          }
          if (owner) {
            href = `${href}&owner=${owner}`;
          }
          href = resolveBindingsFromWorkflowIfNeeded(
            href,
            workflowExecutionResult,
            fetchCredentialsDynamically,
          ) as string;
          const openedWindow = window.open(
            href,
            "",
            "toolbar=no,status=no,menubar=no,location=center,scrollbars=no,height=600,width=610",
          );
          const [succeded, errorMessage] =
            yield waitForLocalStorageKeyOrWindowClosed(
              LS_OAUTH_REDIRECT_FINISHED,
              "true",
              LS_OAUTH_ERROR_KEY,
              openedWindow,
            );
          if (succeded) {
            yield put({
              type: ReduxActionTypes.DYNAMIC_FORM_BUTTON_CLICK_SUCCESS,
              payload: actionPayload.payload,
            });
          } else {
            openedWindow?.close();
            yield put({
              type: ReduxActionErrorTypes.DYNAMIC_FORM_BUTTON_CLICK_ERROR,
              payload: {
                buttonType: actionPayload.payload.buttonType,
                error: errorMessage,
              },
            });
          }
        } catch (error: any) {
          yield put({
            type: ReduxActionErrorTypes.DYNAMIC_FORM_BUTTON_CLICK_ERROR,
            payload: {
              buttonType: actionPayload.payload.buttonType,
              error: `Failed to connect to your account: ${error?.message}`,
            },
          });
        }
        break;
      }
      default:
        yield put({
          type: ReduxActionErrorTypes.DYNAMIC_FORM_BUTTON_CLICK_ERROR,
          payload: {
            error: new Error(`Unknown button type`),
          },
        });
    }
  } catch (error: any) {
    yield put({
      type: ReduxActionErrorTypes.DYNAMIC_FORM_BUTTON_CLICK_ERROR,
      payload: {
        error: `Unknown error when handling button click: ${error?.message}`,
      },
    });
  }
}

function* buttonClickSuccessSaga(
  actionPayload: ReduxAction<ButtonClickPayload>,
) {
  try {
    const integrationId = actionPayload.payload.eventAttributes.integrationId;
    const integrationConfigurationId =
      actionPayload.payload.eventAttributes.configurationId;
    const authType = actionPayload.payload.eventAttributes.authType as AuthType;
    const authConfig = actionPayload.payload.eventAttributes
      .authConfig as AuthConfig;
    const authId = getAuthId(
      authType,
      authConfig,
      integrationId,
      integrationConfigurationId,
    );
    const tokenScope = actionPayload.payload.eventAttributes.authConfig
      ?.tokenScope as TokenScope;
    switch (actionPayload.payload.buttonType) {
      case "connectOAuth": {
        if (integrationId && integrationConfigurationId) {
          yield callSagas([
            getConnectedTokensSaga.apply({
              integrationId: integrationId,
              integrationConfigurationId: integrationConfigurationId,
              authId,
              tokenScope,
            }),
          ]);
        }
        // initiate loading the button again
        yield put(
          loadDynamicFormButtonInit({
            buttonType: "connectOAuth",
            eventAttributes: {
              integrationId,
              integrationConfigurationId,
              authType,
              authConfig,
            },
          }),
        );
        // initiate loading for all dependent buttons
        yield put(
          loadDynamicFormButtonInit({
            buttonType: "revokeOAuthTokens",
            eventAttributes: {
              integrationId,
              integrationConfigurationId,
              authType,
              authConfig,
            },
          }),
        );
        break;
      }
      case "revokeOAuthTokens": {
        if (integrationId && integrationConfigurationId) {
          yield callSagas([
            getConnectedTokensSaga.apply({
              integrationId: integrationId,
              integrationConfigurationId: integrationConfigurationId,
              authId,
              tokenScope,
            }),
          ]);
          // initiate loading the button again
          yield put(
            loadDynamicFormButtonInit({
              buttonType: "revokeOAuthTokens",
              eventAttributes: {
                integrationId,
                integrationConfigurationId,
                authType: actionPayload.payload.eventAttributes.authType,
                authConfig: actionPayload.payload.eventAttributes.authConfig,
              },
            }),
          );
          // initiate loading for all dependent buttons
          yield put(
            loadDynamicFormButtonInit({
              buttonType: "connectOAuth",
              eventAttributes: {
                integrationId,
                integrationConfigurationId,
                authType,
                authConfig,
              },
            }),
          );
        }
        break;
      }
      default:
        yield put({
          type: ReduxActionErrorTypes.DYNAMIC_FORM_BUTTON_CLICK_ERROR,
          payload: {
            error: new Error(`Unknown button type`),
          },
        });
    }
  } catch (error: any) {
    yield put({
      type: ReduxActionErrorTypes.DYNAMIC_FORM_BUTTON_CLICK_ERROR,
      payload: {
        error,
      },
    });
  }
}

function waitForLocalStorageKeyOrWindowClosed(
  key: string,
  desiredValue: string,
  alternativeKey: string,
  openedWindow?: Window | null,
): Promise<[boolean, string | undefined]> {
  return new Promise<[boolean, string | undefined]>((resolve) => {
    const currentValue = localStorage.getItem(key);
    if (currentValue === desiredValue) {
      resolve([true, ""]);
      return;
    }

    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === key && event.newValue === desiredValue) {
        cleanup();
        resolve([true, ""]);
      }
      if (event.key === alternativeKey && !isEmpty(event.newValue)) {
        cleanup();
        resolve([false, event.newValue as string]);
      }
    };

    const checkWindowClosed = () => {
      if (openedWindow && openedWindow.closed) {
        cleanup();
        resolve([false, undefined]);
      }
    };

    const cleanup = () => {
      window.removeEventListener("storage", handleStorageChange);
      if (intervalId) {
        clearInterval(intervalId);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    // Set up an interval to check every 500ms if the window is closed.
    const intervalId = setInterval(checkWindowClosed, 500);
  });
}

export default function* dynamicFormSagas() {
  yield takeEvery(
    ReduxActionTypes.LOAD_DYNAMIC_FORM_BUTTON_INIT,
    loadButtonSaga,
  );
  yield takeEvery(ReduxActionTypes.DYNAMIC_FORM_BUTTON_CLICK, buttonClickSaga);
  yield takeEvery(
    ReduxActionTypes.DYNAMIC_FORM_BUTTON_CLICK_SUCCESS,
    buttonClickSuccessSaga,
  );
}
function resolveBindingsFromWorkflowIfNeeded(
  sourceStringWithBindings: string | undefined,
  workflowExecutionResult: ApiExecutionResponseDto,
  fetchCredentialsEnabled: boolean,
): string | undefined {
  if (!fetchCredentialsEnabled) {
    return sourceStringWithBindings;
  }
  const workflowName = workflowExecutionResult?.apiName;
  const stepName = Object.keys(
    workflowExecutionResult?.context?.outputs ?? {},
  )[0];
  const stepOutput =
    workflowExecutionResult?.context.outputs?.[stepName].output;
  sourceStringWithBindings = sourceStringWithBindings?.replace(
    /\{{(.*?)\}}/g,
    (_, path) => {
      const bindingPathInStepOutput = path.replace(
        `${workflowName}.response.`,
        "",
      );
      const bindingValue = get(stepOutput, bindingPathInStepOutput);
      if (!bindingValue) {
        throw new Error(
          `Failed to resolve binding: {{${path}}}. Please make sure the workflow output has this property.`,
        );
      }
      return bindingValue;
    },
  );
  return sourceStringWithBindings;
}
