import { get } from "lodash";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";
import { updateWidgetProperties } from "legacy/actions/controlActions";
import { getWidgetPropertiesById } from "legacy/selectors/propertyPaneSelectors";
import { selectWidgetDisplayName } from "legacy/selectors/sagaSelectors";
import { ItemKinds, ItemTypeNonWidget } from "../ItemKindConstants";
import { extractPartsFromNestedItemId } from "../NestedItemsUtils";
import type { ItemKindAccessor } from "../ItemKinds";
import type { WidgetProps } from "legacy/widgets";
import type { NestedItemProps } from "legacy/widgets/NestedItem";
import type { AppState } from "store/types";

export const NestedItemAccessor: ItemKindAccessor<ItemKinds.NESTED_ITEM> = {
  useItemName: (itemProperties) => {
    const displayName = useSelector(
      useCallback(
        (state: AppState) =>
          selectWidgetDisplayName(state, itemProperties.widgetId),
        [itemProperties.widgetId],
      ),
    );

    return useMemo(() => {
      return {
        name: "TBD - TODO", // TODO: define name
        displayName, // TODO: use the menu item display name or its parent, not the widget's
        editable: false,
        requiresValidation: false,
      };
    }, [displayName]);
  },
  itemType: (itemProperties) => ItemTypeNonWidget.NESTED_ITEM,
  // TODO: review types here: WidgetProps & NestedItemProps ???
  // Widgets props are necessary to render the props from nested items
  useItemProperties: (itemId: string): WidgetProps & NestedItemProps => {
    const { widgetId, path } = extractPartsFromNestedItemId(itemId);
    const widgetProps = useSelector(
      useCallback(
        (state: AppState) => getWidgetPropertiesById(state, widgetId),
        [widgetId],
      ),
    );

    if (!path) {
      throw new Error("Missing path");
    }

    const nestedProps = get(widgetProps, path);
    return {
      ...nestedProps,
      path,
      widgetId,
    };
  },
  updateItemProperties: (
    dispatch: Dispatch,
    properties: NestedItemProps,
    updates: Record<string, unknown>,
  ) => {
    const nestedUpdates = Object.fromEntries(
      Object.entries(updates).map(([property, value]) => {
        const nestedProperty = [properties.path, property].join(".");
        return [nestedProperty, value];
      }),
    );

    dispatch(updateWidgetProperties(properties?.widgetId, nestedUpdates));
  },
  deleteItemProperties: (
    dispatch: Dispatch,
    properties: any,
    propertyPaths: string[],
  ) => {
    // TODO: implement
  },
  deleteItem: (dispatch, widgetId, _itemScope, deleteAllSelected) => {
    // TODO: Implement
  },
};
