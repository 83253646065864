import Tooltip from "antd/lib/tooltip";
import React, {
  useCallback,
  useContext,
  useMemo,
  useState,
  useRef,
} from "react";
import styled from "styled-components";
import { ReactComponent as LightbulbIcon } from "assets/icons/common/lightbulb.svg";
import { ReactComponent as PasteIcon } from "assets/icons/common/paste-arrow.svg";
import { ReactComponent as RetryIcon } from "assets/icons/common/retry.svg";
import { ReactComponent as SendIcon } from "assets/icons/common/send.svg";

import CodeEditor from "components/app/CodeEditor";
import {
  EditorModes,
  EditorSize,
  EditorTheme,
  TabBehaviour,
} from "components/app/CodeEditor/EditorConfig";
import { CopyButton } from "components/ui/CopyButton";
import { JwtContext } from "hooks/ui/JwtProvider";
import { ReactComponent as ErrorIcon } from "legacy/assets/icons/ads/error.svg";
import { ReactComponent as CloseIcon } from "legacy/assets/icons/control/close.svg";
import { StatusCodes } from "utils/http";
import { isUserOnMac } from "utils/navigator";
import { DiffViewer } from "./DiffViewer";
import LoadingIndicator from "./LoadingIndicator";
import { ParsedJsonViewer } from "./ParsedJsonViewer";
import PromptEditor from "./PromptEditor";
import {
  BetaTag,
  CloseButton,
  Header,
  Title,
  InputSection,
  SubmitButton,
  SubmitSectionWrapper,
  PasteButton,
  ResponseSection,
  ErrorMessage,
  ResponseActionsWrapper,
  Row,
  CancelButton,
  LoadingIndicatorWrapper,
  TitleSection,
  ResponseEditorWrapper,
  Footer,
  RainbowBoxShadow,
  PillButton,
  Pills,
} from "./Shared";
import { AiAssistantOptionType, AiAssistantRequest } from "./constants";
import { queryAiAssistant } from "./query";

const autoCompleteConfig = {
  env: true,
};

const RetryButton = styled(PasteButton)`
  margin-top: 12px;
`;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const AiBaseFlow = (props: {
  onConfirm: (
    aiAssistantOptionType: AiAssistantOptionType,
    code?: string,
  ) => void;
  onClose: () => void;
  option: AiAssistantOptionType;
  getRequestBody: (userInput: string) => AiAssistantRequest;
  editorMode: EditorModes;
  title: string;
  placeholder: string;
  diffViewerProps?: {
    originalCode: string;
    firstLineNumber: number;
  };
  responseViewType: "code" | "diff" | "parsed_json";
  initialConfig?: Record<string, unknown>;
  presetOptions?: Array<{ label: string; value: string }>;
}) => {
  const {
    onConfirm,
    onClose,
    option,
    getRequestBody,
    editorMode,
    title,
    placeholder,
    diffViewerProps,
    responseViewType,
    initialConfig,
    presetOptions,
  } = props;
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [userInput, setUserInput] = useState("");
  const [response, setResponse] = useState("");
  const [error, setError] = useState("");
  const [showRetryButton, setShowRetryButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { jwt, isLoadingJwt } = useContext(JwtContext);

  const [messages, setMessages] = useState<string[]>([]);
  const handleMessage = useCallback((message: string) => {
    setMessages((prev) => [...prev, message]);
    setResponse((resp) => resp + message);
  }, []);

  const handlePromptEditorChange = useCallback((val: unknown) => {
    setUserInput(val as string);
  }, []);

  const handleResponseEditorChange = useCallback((val: unknown) => {
    setResponse(val as string);
  }, []);

  const cancelFnRef = useRef<() => void>();
  const onCancel = useCallback(() => {
    if (cancelFnRef.current) {
      cancelFnRef.current();
      setIsLoading(false);
    }
  }, []);

  const onSubmit = useCallback(
    async (value?: string) => {
      const input = value ?? userInput;
      if (!input || isLoading || !jwt) return;
      setError("");
      setResponse("");
      setIsLoading(true);
      setShowRetryButton(false);

      cancelFnRef.current = queryAiAssistant({
        token: jwt,
        option,
        body: getRequestBody(input),
        onMessage: handleMessage,
        onComplete: () => {
          setIsLoading(false);
          buttonRef.current?.focus();
        },
        onError: (error, statusCode) => {
          setIsLoading(false);
          setError(error);
          if (
            statusCode === StatusCodes.TOO_MANY_REQUESTS ||
            statusCode === 8
          ) {
            // this is retryable
            setShowRetryButton(true);
          }
        },
      });
    },
    [userInput, option, handleMessage, jwt, getRequestBody, isLoading],
  );

  const handleRetry = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  const responseInput = useMemo(
    () => ({
      value: response,
      onChange: handleResponseEditorChange,
    }),
    [response, handleResponseEditorChange],
  );

  const handlePresetOption = useCallback(
    (optionValue: string) => {
      setUserInput(optionValue);
      // use set timeout before submitting to allow for codemirror to update with new value first
      setTimeout(() => {
        onSubmit(optionValue);
      }, 50);
    },
    [onSubmit],
  );

  const isDiffView = responseViewType === "diff";
  const responseEditor = useMemo(() => {
    return (
      <CodeEditor
        showLineNumbers={true}
        mode={editorMode}
        key={editorMode}
        tabBehaviour={TabBehaviour.INDENT}
        onSubmit={onSubmit}
        disabled={isLoading}
        theme={EditorTheme.LIGHT}
        size={EditorSize.EXTENDED}
        monospace={true}
        minHeight="32px"
        maxHeight="100%"
        showShortcutMenu={false}
        input={responseInput}
        autocompleteConfiguration={autoCompleteConfig}
        autoScroll={true}
      />
    );
  }, [editorMode, isLoading, onSubmit, responseInput]);
  return (
    <Wrapper data-test="ai-assistant-flow">
      <RainbowBoxShadow>
        <Header>
          <TitleSection>
            <LightbulbIcon />
            <Title>{title}</Title>
            <BetaTag>Beta</BetaTag>
          </TitleSection>
          <CloseButton data-test="ai-assistant-close" onClick={onClose}>
            <CloseIcon />
          </CloseButton>
        </Header>
      </RainbowBoxShadow>
      <InputSection>
        {presetOptions && (
          <Pills>
            {presetOptions.map((option) => (
              <PillButton
                key={option.value}
                onClick={() => handlePresetOption(option.value)}
              >
                {option.label}
              </PillButton>
            ))}
          </Pills>
        )}

        <PromptEditor
          value={userInput}
          onChange={handlePromptEditorChange}
          onSubmit={onSubmit}
          placeholder={placeholder}
        />
        <SubmitSectionWrapper>
          {isLoading ? (
            <LoadingIndicatorWrapper>
              <LoadingIndicator />
              <Tooltip title="Cancel">
                <CancelButton onClick={onCancel} className="cancel-button">
                  <CloseIcon />
                </CancelButton>
              </Tooltip>
            </LoadingIndicatorWrapper>
          ) : (
            <Tooltip title={isUserOnMac() ? "⌘Enter" : "Ctrl+Enter"}>
              <SubmitButton
                onClick={() => onSubmit()}
                $isDisabled={!userInput || isLoadingJwt || !jwt}
              >
                <SendIcon />
              </SubmitButton>
            </Tooltip>
          )}
        </SubmitSectionWrapper>
      </InputSection>
      {response && (
        <>
          <ResponseSection isDiffView={isDiffView}>
            {responseViewType === "parsed_json" ? (
              <ParsedJsonViewer
                messages={messages}
                isLoading={isLoading}
                syntax={option.syntax}
                onResponseChange={handleResponseEditorChange}
                initialConfig={initialConfig}
              />
            ) : isDiffView && diffViewerProps ? (
              <DiffViewer
                syntax={option.syntax}
                oldCode={diffViewerProps.originalCode}
                newCode={response}
                firstLineNumber={diffViewerProps.firstLineNumber}
                responseEditor={responseEditor}
                isLoading={isLoading}
                onConfirm={() => onConfirm(option, response)}
              />
            ) : (
              <Row
                style={{ flex: 2 }}
                $gap={0}
                $height="100%"
                $alignment="stretch"
              >
                <ResponseEditorWrapper
                  width={"calc(100% - 24px)"}
                  className="drag-disabled"
                >
                  {responseEditor}
                </ResponseEditorWrapper>

                <ResponseActionsWrapper>
                  {!isLoading ? (
                    <CopyButton
                      textToCopy={response}
                      ddogActionName="copy-ai-response"
                    />
                  ) : null}
                </ResponseActionsWrapper>
              </Row>
            )}
          </ResponseSection>
          {!isLoading && !showRetryButton && !error && !isDiffView && (
            <Footer $gap={8} $height={32}>
              <PasteButton
                ref={buttonRef}
                autoFocus
                onClick={() => onConfirm(option, response)}
              >
                <PasteIcon />
                Insert
              </PasteButton>
            </Footer>
          )}
        </>
      )}
      {error && (
        <ResponseSection isDiffView={false}>
          <ErrorMessage>
            <ErrorIcon />
            {error}
          </ErrorMessage>
          {showRetryButton && (
            <RetryButton ref={buttonRef} autoFocus onClick={handleRetry}>
              <RetryIcon />
              Try again
            </RetryButton>
          )}
        </ResponseSection>
      )}
    </Wrapper>
  );
};

export default AiBaseFlow;
