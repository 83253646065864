import CodeMirror, { Hint } from "codemirror";
import { PresetOption } from "components/app/CodeEditor/types";
import { renderHint, typeToIcon } from "./util";

type Completion = Hint;

type OptionDef = {
  "!doc"?: {
    docString: string;
  };
  "!name": string;
  "!type"?: string;
};
// this completer is for autocompleting the whole input with a preset of options.
class PresetListCompletion {
  options: PresetOption[] = [];
  rendered: { tooltip: HTMLElement; remove: () => void } | undefined =
    undefined;

  constructor(options: PresetOption[]) {
    this.options = options;
  }

  unregister() {
    this.options = [];
  }

  complete(cm: CodeMirror.Editor) {
    cm.showHint({
      hint: this.getHint.bind(this),
      completeSingle: false,
    });
  }

  makeCompletion(key: string, od: OptionDef & { decrementCursor?: number }) {
    return {
      text: key,
      displayText: key,
      def: od,
      className: od["!type"]
        ? typeToIcon(od["!type"])
        : "CodeMirror-Tern-completion",
    };
  }

  async getHints(cm: CodeMirror.Editor): Promise<{
    from: CodeMirror.Position;
    to: CodeMirror.Position;
    list: Completion[];
  }> {
    const currentInput = cm.getValue().toLowerCase();
    const filteredOptions = this.options.filter((option) =>
      option.value.toLowerCase().startsWith(currentInput),
    );
    const completions = filteredOptions.map((option) => {
      const dt = {
        ...(option.doc
          ? {
              "!doc": {
                docString: option.doc,
              },
            }
          : {}),
        "!type": option.type,
        "!name": option.value,
      };
      return this.makeCompletion(option.value, dt);
    });
    return {
      from: cm.getCursor(),
      to: cm.getCursor(),
      list: completions,
    };
  }

  async getHint(cm: CodeMirror.Editor): Promise<CodeMirror.Hints> {
    const currentInput = cm.getValue().toLowerCase();
    const completion = await this.getHints(cm);

    CodeMirror.on(completion, "close", () => this.remove());
    CodeMirror.on(completion, "update", () => this.remove());
    CodeMirror.on(completion, "select", (cur: any, node: any) => {
      this.remove();
      this.rendered = renderHint(cm, {
        node,
        "!doc": cur.def?.["!doc"],
        "!type": cur.def?.["!type"],
      });
    });

    const cursor = cm.getCursor();
    return {
      ...completion,
      list: completion.list,
      from: { line: cursor.line, ch: cursor.ch - currentInput.length },
      to: cursor,
    };
  }

  remove() {
    if (this.rendered) {
      this.rendered.remove();
      delete this.rendered;
    }
  }
}

export default PresetListCompletion;
